import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/system/Box';


import { resetPasswordRequest } from './../../Recoil/Selectors/Auth'

import { marginStyles } from '../../Common/Styles/Margins';

const RequestResetPasswordForm = (params: any) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const onSubmit = useRecoilCallback(({snapshot}) => async (values: any) => {
    const { result, success } = await snapshot.getPromise(resetPasswordRequest({ Signer_Email: values.Signer_Email }));
    if (success) {
      setAlert({ message: result.message, status: 'success' })
    } else {
      setAlert({ message: 'Failed to Reset Password', status: 'error' })
    }
  })
  const validation = Yup.object().shape({
    Signer_Email: Yup.string().email('Invalid Email').required('Please Enter Your Email'),
  })

  return (
    <Formik
      initialValues={{ Signer_Email: '' }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              label='Email'
              name='Signer_Email'
              variant='outlined'
              size='small'
              fullWidth
              value={values.Signer_Email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.Signer_Email as boolean && errors.Signer_Email as string}
              error={Boolean(touched.Signer_Email && errors.Signer_Email)}
            />
          </Grid>
          { alert &&
            <Box my={2}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Link to='/login'>
              <Button className={marginClasses.mr1} color='primary' size='small'>
                Back to login
              </Button>
            </Link>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              size='small'
              disabled={isSubmitting}
            >
              {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { RequestResetPasswordForm };
