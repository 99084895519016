import React, { useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Typography
} from '@material-ui/core';
import { UsersTableHead } from './UsersTableHead';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { useStyles } from './Styles/UsersList';
import { DeactivateConfirmation } from './DeactivateConfirmation';

const UsersList = ((props: any) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [confirmationModal, handleConfirmationModal] = useState(false);
  const { users } = props;

  return (
    <div className={classes.root} hidden={props.hide}>
      {props.loading ?
        <PageLoad /> :
        <>
          <DeactivateConfirmation
            open={confirmationModal}
            handleConfirmationModal={handleConfirmationModal}
            user={user}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium'
              aria-label='enhanced table'
            >
              <UsersTableHead
                type={props.type}
              />
              <TableBody>
                { props?.loading &&
                    <TableRow>
                        <TableCell colSpan={3}></TableCell>
                        <TableCell colSpan={5}>
                            <CircularProgress />
                        </TableCell>
                    </TableRow>
                }
                { users.map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.Id}>
                      <TableCell>{row.Signer_First}</TableCell>
                      <TableCell>{row.Signer_Last}</TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>
                        {
                          row.Signer_Status === 'active' ?
                            <Typography
                              className={classes.actionLabels}
                              color='primary'
                              variant='body2'
                              onClick={e => {
                                e.stopPropagation();
                                setUser(row);
                                handleConfirmationModal(true);
                              }}>
                              Deactivate
                            </Typography>
                            :
                            <Typography
                              className={classes.actionLabels}
                              color='primary'
                              variant='body2'
                              onClick={e => {
                                e.stopPropagation();
                                setUser(row);
                                handleConfirmationModal(true);
                              }}>
                              Activate
                            </Typography>
                        }
                      </TableCell>
                    </TableRow>
                  )})}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </div>
  );
});

export { UsersList };
