import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const MobilePhone = ({ values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
      <Grid item xs={12} md={6}>
        { disabled ?
          <TextField
            label='Mobile Phone'
            name='mobile_phone'
            variant='outlined'
            size='small'
            fullWidth
            value={values.mobile_phone}
            disabled={disabled}
          /> :
          <InputMask
            mask='(999) 999-9999'
            maskChar=''
            value={values.mobile_phone}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {() =>
              <TextField
                label='Mobile Phone'
                name='mobile_phone'
                variant='outlined'
                size='small'
                fullWidth
                helperText={touched.mobile_phone && errors.mobile_phone}
                error={Boolean(touched.mobile_phone && errors.mobile_phone)}
              />
            }
          </InputMask>
        }
      </Grid>
  );
}

export { MobilePhone };
