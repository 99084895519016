import React, { useState } from 'react';
import { Grid, Typography, FormControl, FormControlLabel, Checkbox, Link } from '@material-ui/core';

import { GeneralModal } from '../../Modal/';

  const ConfirmationOptions = ({ values, setFieldValue }) => {
  const [open, setOpen] = useState(false);

  const getValue = (value: String) => {
    const communication = [ ...values.prfCom ]
    const optionIndex = values.prfCom.indexOf(value)
    if (optionIndex === -1) {
      communication.push(value)
    } else {
      communication.splice(optionIndex, 1)
    }
    return communication
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleAgree = () => {
    setFieldValue('prfCom', getValue('Text message'));
    handleClose();
  }

  const handleTextMessage = (e) => {
    if (!values.prfCom.includes(e.target.value)) {
      handleClickOpen();
    } else {
      setFieldValue('prfCom', getValue(e.target.value));
    }
  }

  const Message = () => {
    return (
      <Grid alignContent='center' alignItems='center'>
        <Typography>
          Do you Agree with the 
          <Link href='#' onClick={(e) => e.preventDefault()}>
            terms and conditions
          </Link>
          of the SMS service from PacDocSign.
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='body2'>I Want To Receive Confirmation Via</Typography>
        <FormControl component='fieldset'>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.prfCom.includes('Email')}
                  value = 'Email'
                  onChange={(e)=> setFieldValue('prfCom', getValue(e.target.value))}
                  name='ConfirmationOptions'
                  color='primary'
                />
              }
              label='Email'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value = 'Text message'
                  checked={values.prfCom.includes('Text message')}
                  onChange={handleTextMessage}
                  name='ConfirmationOptions'
                  color='primary'
                />
              }
              label='Text Message'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value = 'Phone'
                  checked={values.prfCom.includes('Phone')}
                  onChange={(e) => setFieldValue('prfCom', getValue(e.target.value))}
                  name='ConfirmationOptions'
                  color='primary'
                />
              }
              label='Phone'
            />
          </Grid>
        </FormControl>
      </Grid>
      <GeneralModal
        open={open}
        title='PacDocSign'
        openModal={handleClose}
        handleSubmit={handleAgree}
        submitLabel='Agree'
      >
        <Message />
      </GeneralModal>
    </>
  );
}

export { ConfirmationOptions };
