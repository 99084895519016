import React, { useState } from 'react';
import { useRecoilState, useRecoilCallback, useSetRecoilState } from 'recoil';
import { FormControl, MenuItem, InputLabel, Select, TextField } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack'
import  LoadingScreen  from '../../ThemeComponents/components/LoadingScreen';

import { GeneralModal } from '../Modal';
import { currentOrder, refetchOrders } from '../../Recoil/Atoms/Orders';
import { changeOrderStatus, sendMailToPacdoc } from '../../Recoil/Selectors/Orders';
import { storage } from '../../Services/storage';

export const OrderChangeStatus = ((props: any) => {
  const { handleStatusModal, open } = props
  const [explanation, setExplanation] = useState('');
  const [loading, setLoading] = useState(false);


  const [order, setOrder]: any = useRecoilState(currentOrder);
  const callRefetch = useSetRecoilState(refetchOrders);
  const user: any = JSON.parse(storage.get('user') || "{}")

  const refetch = () => callRefetch(n => n + 1);

  const totalStatuses = [
    'Signed',
    'Borrower Would Not Sign',
    'Borrower No Show',
    'Cancelled By Borrower',
    'Cancelled By Client',
    'Late Cancellation - Print Fee'
  ];

  const updateStatus = useRecoilCallback(({ snapshot }) => async () => {
    setLoading(true)
    const res = await snapshot.getPromise(changeOrderStatus({
      Id: order.Id,
      explanation,
      status: order?.f_status_web
    }));
    snapshot.getPromise(sendMailToPacdoc({
      f_loan_number: order.w_Loan_Number,
      status: 'updateBySigner',
      f_status_web: order.f_status_web,
      username: user.username
    }));
    if (res.success) {
      setLoading(false)
      refetch()
      handleStatusModal(false)
      enqueueSnackbar(
        'Order Status Updated',
        {
          variant: 'success'
        }
      )
    } else {
      enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });

  const onChangeStatus = e => {
    setOrder({
      ...order,
      f_status_web: e.target.value,
    })
  }
  return (
    <>
      <GeneralModal
        title='Change Order Status'
        submitLabel='Submit'
        handleSubmit={updateStatus}
        openModal={e => handleStatusModal(false)}
        open={open}
      >
        <FormControl
          fullWidth
          variant='outlined'
          size='small'
        >
          <InputLabel>Status</InputLabel>
          <Select label='Clients' value={order?.f_status_web} onChange={onChangeStatus}>
            {totalStatuses.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>
        {order?.f_status_web === 'Borrower Would Not Sign' || order?.f_status_web === 'Borrower No Show' ?
          <>
            <br></br>
            <br></br>
            <TextField
              multiline
              label='Explanation'
              rows={4}
              fullWidth
              variant='outlined'
              value={explanation}
              onChange={e => setExplanation(e.target.value)}
            >
            </TextField>
          </> : ''}
         {loading === true && <LoadingScreen/>}
      </GeneralModal>
    </>
  );
});
