import React from 'react'
import { Typography, Grid, CircularProgress, SvgIcon } from '@material-ui/core';
import Box from '@mui/system/Box';

import { Trash } from 'react-feather';
import moment from 'moment';

import { useStyles } from './Style/Style';

const FileList = ({ uploadedFiles, total, files, getFile, removeFile, merged, loading }) => {
  const classes = useStyles();

  return (
    <Grid direction='column' container classes={{ root: classes.fileListWrapper }}>
      { uploadedFiles.state === 'loading' ?
        <Box my={2} display='flex' justifyContent='center'>
          <CircularProgress />
        </Box> :
        total === 0 && uploadedFiles.state !== 'loading' ?
          <Typography variant='h4' align='center'>NO FILE HAS BEEN UPLOADED YET</Typography> :
          <>
            <Typography variant='h4'>List of Files</Typography>
            { files.map(file => {
              const time = file.created ? moment(new Date(file.created)).format('MM/DD/YYYY hh:mm A') : '';
              return (
                <Box
                  className={classes.fileContainer}
                  key={file.created}
                  display='grid'
                  gridAutoFlow='column'
                  gridTemplateColumns={'1fr 1fr 1fr'}
                  px={2}
                  py={1}
                  mt={2}
                >
                  <Typography
                    className={classes.fileLabel}
                    color='primary'
                    variant='body2'
                    onClick={() => getFile(file.name)}
                  >
                    {loading === file.name ? <CircularProgress size={10} /> : ''} {file.name} 
                  </Typography>
                  <Typography variant='caption'>Uploaded By: {file.createdBy}</Typography>
                  <Typography variant='caption'>Uploaded On: {time}</Typography>
                  {!merged &&
                    <SvgIcon
                      className={classes.deleteIcon}
                      fontSize='small'
                      color='primary'
                      onClick={() => removeFile(file.name)}
                    >
                    <Trash />
                  </SvgIcon>
                  }
                </Box>
              )
            })}
          </>
      }
    </Grid>
  )
}

export { FileList };
