import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const CoBorrowerName = ({ location, values, handleChange, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant='body2'>Co-Borrower's Name</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <TextField
          label='First Name'
          name={`${location}.w_Co_Borrower_First_Name`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.w_Co_Borrower_First_Name}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <TextField
          label='Last Name'
          name={`${location}.w_Co_Borrower_Last_Name`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.w_Co_Borrower_Last_Name}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { CoBorrowerName };
