import React, { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { PasswordChecklist } from './../PasswordChecklist';

import { SignerAddress } from './Fields/SignerAddress';
import { PaymentAddress } from './Fields/PaymentAddress';
import { PhoneType } from './Fields/PhoneType';
import { ReceiveTextMessage } from './Fields/ReceiveTextMessage';
import { NNACertifiedNotary } from './Fields/NNACertifiedNotary';

const Fields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}) => {
  const [hideAddress, setHideAddress] = useState(false);

  const setPaymentAddressAsSingerAddress = () => {
    setFieldValue('payment_address', values.address);
    setFieldValue('payment_city', values.city);
    setFieldValue('payment_state', values.state);
    setFieldValue('payment_zip', values.zip);
    setFieldValue(
      'pmntAdrsSameAsAdrs',
      values.pmntAdrsSameAsAdrs ? false : true
    );
    setHideAddress(!values.pmntAdrsSameAsAdrs);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="Company Name"
          name="company_name"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.company_name && errors.company_name}
          error={Boolean(touched.company_name && errors.company_name)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="First Name"
          name="Signer_First"
          variant="outlined"
          size="small"
          fullWidth
          onChange={e => {
            e.target.value =
              e.target.value.charAt(0).toUpperCase() +
              e.target.value.substr(1).toLowerCase();
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={touched.Signer_First && errors.Signer_First}
          error={Boolean(touched.Signer_First && errors.Signer_First)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Last Name"
          name="Signer_Last"
          variant="outlined"
          size="small"
          fullWidth
          onChange={e => {
            e.target.value =
              e.target.value.charAt(0).toUpperCase() +
              e.target.value.substr(1).toLowerCase();
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={touched.Signer_Last && errors.Signer_Last}
          error={Boolean(touched.Signer_Last && errors.Signer_Last)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="(999) 999-9999"
          maskChar=""
          value={values.mobile_phone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              label="Mobile Number"
              name="mobile_phone"
              variant="outlined"
              size="small"
              fullWidth
              helperText={touched.mobile_phone && errors.mobile_phone}
              error={Boolean(touched.mobile_phone && errors.mobile_phone)}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="(999) 999-9999"
          maskChar=""
          value={values.home_phone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              label="Home Phone"
              name="home_phone"
              variant="outlined"
              size="small"
              fullWidth
              helperText={touched.home_phone && errors.home_phone}
              error={Boolean(touched.home_phone && errors.home_phone)}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Email"
          name="Signer_Email"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.Signer_Email as boolean && errors.Signer_Email as string}
          error={Boolean(touched.Signer_Email && errors.Signer_Email)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Team Manager"
          name="Contact_Team_Manager"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.Contact_Team_Manager && errors.Contact_Team_Manager
          }
          error={Boolean(
            touched.Contact_Team_Manager && errors.Contact_Team_Manager
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Password"
          name="pass"
          variant="outlined"
          size="small"
          type="password"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <PasswordChecklist minLength={8} value={values.pass} iconSize={12} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          variant="outlined"
          size="small"
          type="password"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirmPassword && errors.confirmPassword}
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Authentication Type</InputLabel>
          <Select
            label="Authentication Type"
            name="auth_type"
            fullWidth
            defaultValue={values.auth_type}
            onChange={handleChange}
            error={Boolean(touched.auth_type && errors.auth_type)}
          >
            <MenuItem value="password">Password</MenuItem>
            <MenuItem value="two-factor">Two Factor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <PhoneType
          touched={touched}
          values={values}
          label={true}
          errors={errors}
          handleChange={handleChange}
        />
        <ReceiveTextMessage
          touched={touched}
          errors={errors}
          values={values}
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <NNACertifiedNotary
          errors={errors}
          touched={touched}
          values={values}
          handleChange={handleChange}
        />
      </Grid>
      <SignerAddress
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        disabled={false}
        hideAddress={hideAddress}
      />
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              //  checked={values.pmntAdrsSameAsAdrs}
              defaultChecked={values.pmntAdrsSameAsAdrs}
              onChange={setPaymentAddressAsSingerAddress}
              name="pmntAdrsSameAsAdrs"
              color="primary"
            />
          }
          label="Same as Signer Address"
        />
      </Grid>
      {!values.pmntAdrsSameAsAdrs && (
        <PaymentAddress
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          disabled={false}
        />
      )}
    </Grid>
  );
};

export { Fields };
