import React from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CardMedia,
} from '@material-ui/core';
import Box from '@mui/system/Box';


import { storage } from '../../Services/storage';
import GeneralAccordion from '../Accordion';

import { useStyles } from './Styles/index';

const GeneralStepper = (props: any) => {

    const classes = useStyles();

    const { closeModal } = props
    const user = storage.get('user')
    let parsedUser: any = {}

    if (user) {
        parsedUser = JSON.parse(user)
    }

    function getSteps() {
        return ['Compliance Requirements', 'Missing Documents'];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography>
                            Dear Signing Agent,
                        </Typography>

                        <Typography>
                            We have identified some of the missing/expired documents necessary on your profile.
                            Please take a moment and review and upload the missing/expired documents immediately.
                        </Typography>
                    </>
                )
            case 1:
                return <GeneralAccordion />;
            case 2:
                return (
                    <CardMedia component='iframe' src='https://www.youtube.com/embed/06ah9arELG4' className={classes.stepperVideo} />
                )
            default:
                return 'unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);

    const steps = getSteps();
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
        <Box>
            {parsedUser?.countDown &&
                <Typography variant='caption'>
                    {parseInt(parsedUser?.countDown)} day(s) left to upload compliance documents.
                </Typography>
            }
            <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>
                {activeStep === steps.length ? (
                    <Box className={classes.container}>
                        <Typography className={classes.content}>All Steps Completed</Typography>
                        <Box className={classes.stepperButtonSet}>
                            <Button className={classes.stepperButton} variant='contained' color='secondary' onClick={closeModal}>Close</Button>
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.container}>
                        <Typography className={classes.content}>{getStepContent(activeStep)}</Typography>
                        <Box className={classes.stepperButtonSet}>
                            <Button className={classes.stepperButton} variant='outlined' color='primary' disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button className={classes.stepperButton} variant='contained' color='secondary' onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default GeneralStepper;