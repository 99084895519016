import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const EmergencyContactLastName = ({ values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <br></br>
      <TextField
        label='Emergency Last Name'
        name='emergency_last_name'
        variant='outlined'
        size='small'
        fullWidth
        value={values.emergency_last_name}
        onChange={ e => { 
          e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
          handleChange(e)}}
        onBlur={handleBlur}
        helperText={touched.emergency_last_name && errors.emergency_last_name}
        error={Boolean(touched.emergency_last_name && errors.emergency_last_name)}
        disabled={disabled}
      />
    </Grid>
  );
}

export { EmergencyContactLastName };
