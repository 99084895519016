import React, { useState } from 'react';
import { useRecoilCallback, useRecoilValueLoadable, useRecoilState } from 'recoil';
import { Button, CircularProgress, Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';
import Box from '@mui/system/Box';

import { Toast } from '../Toasts';
import { validationSchema } from '../../Common/Rules/ProfileForm';

import { ProfilePicture } from './SignerFields/ProfilePicture'
import { SignerType } from './SignerFields/SignerType'
import { SignerEmail } from './SignerFields/SignerEmail'
import { FirstName } from './SignerFields/FirstName'
import { LastName } from './SignerFields/LastName'
import { CompanyName } from './SignerFields/CompanyName'
import { CommissioningStates } from './SignerFields/CommissioningStates'
import { MobilePhone } from './SignerFields/MobilePhone'
import { HomePhone } from './SignerFields/HomePhone'
import { SignerAddress } from './SignerFields/SignerAddress'
import { PaymentAddress } from './SignerFields/PaymentAddress'
import { EmergencyContactFirstName } from './SignerFields/EmergencyContactFirstName'
import { EmergencyContactLastName } from './SignerFields/EmergencyContactLastName'
import { EmergencyContactPhone } from './SignerFields/EmergencyContactPhone'
import { NSANumber } from './SignerFields/NSANumber'
import { ReceiveTextMessage } from './SignerFields/ReceiveTextMessage'
import { ConfirmationOptions } from './SignerFields/ConfirmationOptions'
import { NotifiedVia } from './SignerFields/NotifiedVia'
import { PhoneType } from './SignerFields/PhoneType'
import { NNACertifiedNotary } from './SignerFields/NNACertifiedNotary'
import { SetsOfDocumentLoan } from './SignerFields/SetsOfDocumentLoan'
import { Languages } from './SignerFields/Languages'

import { PageLoad } from './../../Components/LoadingScreen/PageLoad';
import { createProfile } from './../../Common/CreatePayload'
import { initialValues } from './../../Common/Rules/SignerForm'

import { updateProfile, getProfile } from '../../Recoil/Selectors/Auth';
import { updateUser } from '../../Recoil/Atoms/Login';

import { marginStyles } from '../../Common/Styles/Margins';

const ProfileForm = () => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const [userAtom, setUserAtom] = useRecoilState(updateUser)
  const reloadUser = () => { setUserAtom(userAtom + 1) }

  const profileRequest: any = useRecoilValueLoadable(getProfile)
  const profile = profileRequest?.contents?.result || null
  const user = profile === null ? initialValues : createProfile(profile, initialValues)

  const setPaymentAddress = (setFieldValue: any, values: any) => {
    setFieldValue('payment_address', values.address)
    setFieldValue('payment_city', values.city)
    setFieldValue('payment_state', values.state)
    setFieldValue('payment_zip', values.zip)
    setFieldValue('pmntAdrsSameAsAdrs', !values.pmntAdrsSameAsAdrs)
  }

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    setSubmitting(true);
    const res = await snapshot.getPromise(updateProfile(values));
    setSubmitting(false);
    reloadUser()
    if (res.success) {
      setToast({
        message: 'Profile Updated Successfully!',
        type: 'success',
        open: true
      });
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
    }
  });

  return (
    profileRequest.state === 'loading' || profile === null ?
      <PageLoad />
      :
      <>
        <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
        <Formik
          initialValues={user}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }: any) => {
            return (
              <Form onSubmit={handleSubmit} placeholder={''}>
                <Grid container spacing={4}>
                  <ProfilePicture />
                </Grid>
                <Grid container alignItems='center' spacing={4} sm={12}>
                  <SignerType
                    values={values}
                    handleChange={handleChange}
                  />
                  <SignerEmail
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </Grid>
                <Grid container alignItems='center' spacing={4} sm={12}>
                  <FirstName
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                  <LastName
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </Grid>
                <Grid container spacing={4} sm={12}>
                  <CompanyName
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                  <Grid item xs={12} sm={12} md={6} >
                    <CommissioningStates
                      values={values.commisioning_states[0]}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      disabled={false}
                      i={0}
                    />
                    <br></br>
                    <CommissioningStates
                      values={values.commisioning_states[1]}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      disabled={false}
                      i={1}
                    />
                    <br></br>
                    <CommissioningStates
                      values={values.commisioning_states[2]}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      disabled={false}
                      i={2}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4} sm={12}>
                  <MobilePhone
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                  <HomePhone
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </Grid>
                <SignerAddress
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={false}
                  hideAddress={false}
                />
                <Grid item xs={12} md={6} className={marginClasses.mt2}>
                  <br></br>
                  <Typography variant='h4'>Payment Address</Typography>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.pmntAdrsSameAsAdrs}
                      onChange={() => setPaymentAddress(setFieldValue, values)}
                      name='hidePaymentAddress'
                      color='primary'
                    />
                  }
                  label='Same as Signer Address'
                />

                {
                  !values.pmntAdrsSameAsAdrs &&
                  <PaymentAddress
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    disabled={false}
                  />
                }
                <Grid container spacing={4} >
                  <EmergencyContactFirstName
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                  <EmergencyContactLastName
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </Grid>
                <Grid container spacing={4}>
                  <EmergencyContactPhone
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                  <NSANumber
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <ReceiveTextMessage
                    values={values}
                    handleChange={handleChange}
                  />
                  <ConfirmationOptions
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <NotifiedVia
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <PhoneType
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                  />
                  <NNACertifiedNotary
                    values={values}
                    handleChange={handleChange}
                  />
                  <SetsOfDocumentLoan
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    disabled={false}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Grid>
                <Languages
                  values={values}
                  setFieldValue={setFieldValue}
                />
                { alert &&
                  <Box mt={3}>
                    <Alert severity={alert.status}>{alert.message}</Alert>
                  </Box>
                }
                <Box mt={3} display='flex' justifyContent='flex-end'>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
                  Submit
                </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </>
  );
};

export { ProfileForm };
