import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from '@material-ui/core';

import { useStyles } from './Styles/OrdersTableHead';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  type: string;
}

interface HeadCell {
  id: string;
  label: string;
}



const OrdersTablesHead = (props: EnhancedTableProps) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, type } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  let headCells: HeadCell[] = [];
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.Signer_Account_Type === 'COMPANY') {
    headCells = [
      { id: 'w_Loan_Number', label: 'Loan/Order #' },
      { id: 'w_Borrower_Last_Name', label: 'Last Name' },
      { id: 'w_Appointment_Date', label: 'Appointment Date' },
      { id: 'w_Appointment_Time', label: 'Appointment Time' },
      ...( user && user.Signer_Account_Type === 'COMPANY' ? [{ id: 'sub_signer', label: 'Sub User' }] : [] ),
      { id: 'f_status_web', label: 'Status' },
      ...(type !== 'available' ? [{ id: 'w_Return_Tracking', label: 'Payment Status' }, { id: 'documents', label: 'Document' },] : [{ id: 'distance', label: 'Distance' }]),
      { id: 'actions', label: 'Actions' },
    ];
  } else {
    headCells = [
      { id: 'w_Loan_Number', label: 'Loan/Order #' },
      { id: 'w_Borrower_Last_Name', label: 'Last Name' },
      { id: 'w_Appointment_Date', label: 'Appointment Date' },
      { id: 'w_Appointment_Time', label: 'Appointment Time' },
      { id: 'f_status_web', label: 'Status' },
      ...(type !== 'available' ? [{ id: 'w_Return_Tracking', label: 'Payment Status' }, { id: 'documents', label: 'Document' },] : [{ id: 'distance', label: 'Distance' }]),
      { id: 'actions', label: 'Actions' },
    ];
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { OrdersTablesHead };
