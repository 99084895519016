import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const BorrowerEmail = ({ location, values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant='body2'>Borrower's Email</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <TextField
          label='Email'
          name={`${location}.f_borrower_email`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.f_borrower_email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].f_borrower_email}
          error={Boolean((touched[location] && errors[location]) && errors[location].f_borrower_email)}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { BorrowerEmail };
