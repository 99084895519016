/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import type { FC, ReactNode } from 'react';
import { useRecoilValue } from 'recoil'
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import {  Drawer, List, ListSubheader, makeStyles, useMediaQuery  } from '@material-ui/core';
import Box from '@mui/system/Box';
import { Briefcase, Clock, Edit, Phone } from 'react-feather';
import { useTheme } from '@material-ui/core/styles';
import NavItem from './NavItem';
import { userInfo } from './../../Recoil/Atoms/Login';

import Logo from 'src/ThemeComponents/components/Logo';

interface NavBarProps {
  onMobileClose: (close) => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

interface NavItems {
  items: Item[];
  pathname: string;
  depth?: number;
}

interface ChildRoutes {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}


const renderNavItems = ({ items, pathname, depth = 0 }: NavItems) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

const reduceChildRoutes = ({ acc, pathname, item, depth }: ChildRoutes) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(item ? item?.href : item?.href, pathname);

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useRecoilValue(userInfo)
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  let sections: Section[] = [];
  if (user?.Signer_Account_Type === 'COMPANY') {
    sections = [
      {
        subheader: 'Overview',
        items: [{
          title: 'Orders',
          icon: Briefcase as any,
          href: '/orders'
        }, {
          title: 'Available Orders',
          icon: Briefcase,
          href: '/available-orders'
        },
        {
          title: 'Users',
          icon: Edit,
          href: '/users'
        }, {
          title: 'Hours of Operations',
          icon: Clock,
          href: '/hours-of-operations'
        }, {
          title: 'Contact Us',
          icon: Phone,
          href: '/contact-us'
        }, {
          title: 'Change Password',
          icon: Edit,
          href: '/change-password'
        }]
      }
    ];
  } else {
    sections = [
      {
        subheader: 'Overview',
        items: [{
          title: 'Orders',
          icon: Briefcase as any,
          href: '/orders'
        }, {
          title: 'Available Orders',
          icon: Briefcase,
          href: '/available-orders'
        },
        {
          title: 'Hours of Operations',
          icon: Clock,
          href: '/hours-of-operations'
        }, {
          title: 'Contact Us',
          icon: Phone,
          href: '/contact-us'
        }, {
          title: 'Change Password',
          icon: Edit,
          href: '/change-password'
        }]
      }
    ];
  }


  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      {!isLgUp &&
        <Box display='flex' justifyContent='center'>
          <RouterLink to='/'>
            <Logo height='100px' link='/static/Logo.png' />
          </RouterLink>
        </Box>
      }
      <Box p={2}>
        {sections.map((section) => (
          <List
            key={section.subheader}
            subheader={(
              <ListSubheader disableGutters disableSticky>
                {section.subheader}
              </ListSubheader>
            )}
          >
            {renderNavItems({
              items: section.items,
              pathname: location.pathname
            })}
          </List>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      {isLgUp && 
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onMobileClose(false)}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      }
      {!isMdDown &&
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      }
    </>
  );
};

export default NavBar;
