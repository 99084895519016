import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
// import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment-timezone';
import { Button, Typography, TextField } from '@material-ui/core';
import Box from '@mui/system/Box';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import { useRecoilCallback } from 'recoil';
import { Toast } from '../Toasts';
import signature from './../../Common/Assets/Images/signature.png'
import { storage } from '../../Services/storage';

import { marginStyles } from 'src/Common/Styles/Margins';
import { sendSignedAgreement } from 'src/Recoil/Selectors/Signer';

import { useStyles } from './Style/Style';

const Agreement = (props) => {
    const classes = useStyles();
    const margin = marginStyles();
    const [toast, setToast] = useState({
        message: '',
        type: 'success',
        open: false
    });
    
    const user: any = JSON.parse(storage.get('user') || "{}")
    const { openModal, removeDocType, loading, setLoading } = props

    const [alert, setAlert] = useState(null);
    const [user_ip, setUserIp] = useState(null)
    const [signerSign, setSignerSign] = useState(null)
    const [title, setTitle] = useState('Signing Agent')

    let day = moment.tz("America/Los_Angeles").format('DD');
    let month = moment.tz("America/Los_Angeles").format('MMM');
    let year = moment.tz("America/Los_Angeles").format('YYYY');

    let getUserIp = () => {
        fetch("https://ipinfo.io/json?token=f4d224ca771aa0").then(
            (response) => response.json()
        ).then(
            (jsonResponse) => setUserIp(jsonResponse.ip)
        )
    }

    useEffect(() => {
        getUserIp()
    })

    let initialValues = {
        day,
        month,
        year,
        signer_id: user.signer_id,
        signer_name: `${user.Signer_First} ${user.Signer_Last}`,
        agent_name: "PAC DOC SIGN",
    }

    const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any) => {
        // let signatureBase64 = signatureRef.current.toDataURL();
        setLoading(true)
        let res: any = await snapshot.getPromise(sendSignedAgreement({ ...values, signerSign, user_ip, title }));
        if (!res.success) {
            setAlert({
                status: 'error',
                message: `An Error Occured`
            });
        } else {
            removeDocType('PP')
            setToast({
                message: 'Agreement Updated Successfully!',
                type: 'success',
                open: true
            })
            openModal(false)
        }
        setLoading(false)
    });

    return (
        <>
            <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {alert && (
                            <Box my={2}>
                                <Alert severity={alert.status}>{alert.message}</Alert>
                            </Box>
                        )}
                        <div className={classes.conatiner}>
                            <Typography variant="h4" align="center" >
                                CONFIDENTIALITY, NON-DISCLOSURE AND SECURITY AGREEMENT <br />
                                (SIGNING AGENT)
                            </Typography>
                            <Box>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    THIS CONFIDENTIALITY, NON-DISCLOSURE AND SECURITY AGREEMENT (“Agreement”) is effective this <strong>{day}th of {month} , {year} </strong> (“Effective Date”)
                                    by and between <strong>PACIFIC DOCUMENT SIGNING, INC.</strong>, having a place of business at <strong>10801 National Blvd., Suite 510, Los Angeles, CA 90064 </strong> (“PacDocSign”) and <strong>{user.Signer_First} {user.Signer_Last}</strong> of
                                    business at <strong>{user.address}</strong>, (“Signing Agent”),
                                    hereinafter collectively referred to as the “Parties”.
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    WHEREAS, PacDocSign and Signing Agent engage in a business relationship
                                    which involves the exchange of non-public personal, confidential and/or proprietary
                                    information;
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    WHEREAS, the Gramm-Leach-Bliley Act of 1999 (15 USC 6801, et seq.), as
                                    amended from time to time ( “GLB Act”) and the regulations promulgated thereunder
                                    impose certain obligations on financial institutions with respect to the confidentiality and
                                    security of the customer data of such financial institutions;
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    WHEREAS, the Parties wish to enter into this Agreement to establish the
                                    confidentiality and security obligations of the Signing Agent with respect to certain
                                    Confidential Information as defined below, in order to comply with the GLB Act and the
                                    regulations promulgated thereunder.
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    NOW, THEREFORE, as a prerequisite to the assignment of any closing
                                    documents provided for signing engagements by PacDocSign, and for good and valuable
                                    consideration, the receipt and sufficiency of which are hereby acknowledged, and
                                    intending to be legally bound, the Parties hereby agree as follows:
                                </Typography>

                                <Typography variant="h5">1. <span className={classes.listTitle}>Confidential Information.</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3)}>
                                    For the purposes of this Agreement, “Confidential Information” shall mean all
                                    information related to customers, including without limitation any “non-public personal
                                    information” as defined under the GLB Act and regulations promulgated thereunder in
                                    oral, demonstrative, written, graphic or machine-readable form, whether or not owned or
                                    developed by PacDocSign
                                </Typography>

                                <Typography variant="h5">2. <span className={classes.listTitle}>Disclosure and Protection of Confidential Information.</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    (a) Signing Agent agrees not to use the
                                    Confidential Information for any purpose other than the
                                    fulfillment of Signing Agent’s obligations to PacDocSign. Signing
                                    Agent shall not disclose, publish, release, transfer or otherwise
                                    make available the Confidential Information in any form, except as
                                    necessary to fulfill Signing Agent’s obligations to PacDocSign. If
                                    the GLB Act or other applicable law now or hereafter in effect
                                    impose a higher standard of confidentiality and/or protection to
                                    the Confidential Information, then such standard shall take
                                    precedence over the provisions of this Section.
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    (b) Signing Agent will not make copies of the
                                    Confidential Information for the Signing Agent or any third party,
                                    except for the signatory/ borrower.
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    (c) In addition to any other security
                                    provisions implemented by Signing
                                    <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                        <ol>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that any computer
                                                Signing Agent uses or will use to access loan files is
                                                password protected, and that Signing Agent will delete any
                                                downloaded files after the files are printed.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that any computer
                                                Signing Agent uses or will use to access loan files has
                                                ISCA certified anti-virus software (e.g., Norton, McAfee,
                                                ESET) installed, including current update subscriptions.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that Signing
                                                Agent will utilize only a web-browser with Secure Socket
                                                Layer (SSL) TLS 1.3 or higher protection, and 256-bit or
                                                greater encryption (e.g., Chrome 30+, FireFox 27+,
                                                Microsoft Edge or IE 11+, Apple Safari 9+ ), with these
                                                features active, to access loan files.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that Signing
                                                Agent will shred any extra hardcopy loan file documents
                                                created.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that Signing
                                                Agent will report any security incidents including but not
                                                limited to lost or missing physical documents, lost or
                                                missing components upon which electronic documents were
                                                stored, and any security breaches of any computer that was
                                                used to store or access PacDocSign loan documents. A
                                                police report shall be filed as to any stolen data.
                                                Signing Agent agrees that PacDocSign and/or its agent has
                                                permission to investigate the security incident, possibly
                                                including a physical inspection of Signing Agent’s
                                                computer, to determine the extent of the security breach.
                                            </li>
                                            <Typography>In regard to the physical transport of documents:</Typography>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that Signing
                                                Agent will stow hardcopy loan file documents out of plain
                                                sight, in an area inaccessible to persons other than the
                                                Signing Agent, in Signing Agent’s home or office.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                Signing Agent agrees to and acknowledges that Signing
                                                Agent will keep hardcopy loan documents in a sealable,
                                                opaque wrapper (e.g., envelope) during transport. If the
                                                documents must be left unattended in the car, the
                                                documents shall be placed into the trunk of the car.
                                                Documents shall be placed into the trunk of the car prior
                                                to arrival at the temporary destination to avoid public
                                                awareness of the documents in the trunk of the car.
                                            </li>
                                            <li className={clsx(margin.mt3, margin.mb3)}>
                                                When provided with one, Signing Agent shall use the
                                                shipping label provided by the lender (and associated
                                                courier) for the return of documents.
                                            </li>
                                        </ol>
                                    </Typography>
                                </Typography>

                                <Typography variant="h5">3. <span className={classes.listTitle}>Injunctive Relief</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3)}>
                                    It is agreed that unauthorizeddisclosure or use of any Confidential
                                    Information will cause immediate and/or irreparable injury to PacDocSign,
                                    and that monetary damages would be insufficient to remedy such breach. For
                                    this reason, the Parties therefore acknowledge and agree that
                                    PacDocSign shall be entitled to seek injunctive relief if
                                    necessary to prevent any breach of this Agreement, or unauthorized
                                    disclosure, use, or threat of disclosure or use of Confidential
                                    Information. This right shall be in addition to, and not in lieu
                                    of, any other remedies at law or in equity.
                                </Typography>

                                <Typography variant="h5">4. <span className={classes.listTitle}>Amendments.</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3)}>
                                    This Agreement shall not be amended,
                                    modified, released, discharged, abandoned or otherwise terminated
                                    except by written agreement signed by the parties hereto.
                                </Typography>

                                <Typography variant="h5">5. <span className={classes.listTitle}>Severability; No Waiver.</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    If any provision of this Agreement is held for any reason to be invalid, illegal or
                                    unenforceable, such invalidity, illegality or unenforceability will not affect any other
                                    provisions of this Agreement and this Agreement will be construed as if such invalid,
                                    illegal or unenforceable provision had not been contained herein.
                                </Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    No modification or waiver of any provision of this Agreement will be valid unless
                                    such modification or waiver is in writing and signed by the party against whom it is sought
                                    to be enforced.  No waiver at any time of any provision of this Agreement will be deemed
                                    to be a waiver of any other provision of this Agreement.
                                </Typography>

                                <Typography variant="h5">6. <span className={classes.listTitle}>Choice of Law for Enforcement of This Agreement.</span></Typography>
                                <Typography className={clsx(margin.mt3, margin.mb3, classes.paragraph)}>
                                    If any legal action is required to enforce the terms of this Agreement, this
                                    Agreement will be governed by and construed in accordance with the law of the State of
                                    California.
                                </Typography>
                            </Box>

                            <Box>
                                <Typography>
                                    <strong>IN WITNESS WHEREOF,</strong> the Signing Agent has executed this Agreement as
                                    of the Effective Date.
                                </Typography>
                                <Box className={margin.mt2}>
                                    <table className={classes.Table}>
                                        <tr>
                                            <td><strong>AGREED.</strong></td>
                                            <td><strong>AGREED.</strong></td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <Typography variant='h5'>Disclosing Party</Typography>
                                            </td>
                                            <td>
                                                <Typography variant='h5'>Recieving Party</Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography>By:</Typography>
                                            </td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td className={classes.brbtm}>
                                                <img alt='Signature' width={'125px'} src={signature} />
                                            </td>
                                            <td>
                                                <TextField
                                                    value={signerSign}
                                                    onChange={(e) => setSignerSign(e.target.value)}
                                                    inputProps={{ className: classes.signerSignature }}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography>Printed Name:</Typography>
                                                <Typography>Fred Simab</Typography>
                                            </td>
                                            <td>
                                                <Typography>Printed Name:</Typography>
                                                <Typography>{`${user.Signer_First} ${user.Signer_Last}`}</Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography>Title:</Typography>
                                                <Typography>President.</Typography>
                                            </td>
                                            <td>
                                                <Typography>Title:</Typography>
                                                <TextField
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography>Dated:</Typography>
                                                <Typography>{moment().tz('America/Los_Angeles').format('MM/DD/YYYY')}</Typography>
                                            </td>
                                            <td>
                                                <Typography>Dated:</Typography>
                                                <Typography>{moment().tz('America/Los_Angeles').format('MM/DD/YYYY')}</Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>
                                                <Button disabled={loading} variant="contained" color="primary" type='submit' >SUBMIT</Button>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Box>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export { Agreement };
