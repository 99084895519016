import { Grid, IconButton, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Search, X } from 'react-feather'
import { useRecoilState } from 'recoil'
import { pagination } from 'src/Recoil/Atoms/Orders'

export function SearchOrders() {
  const [pageInfo, setPageInfo] = useRecoilState(pagination)
  const [search, setSearch] = useState(pageInfo.search)
  return (
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <form onSubmit={e => {
          e.preventDefault()
          setPageInfo({
            ...pageInfo, search
          })
        }}>
          <TextField
            label='Search...'
            variant='outlined'
            value={search}
            onChange={e => setSearch(e.target.value)}
            size='small'
            InputProps={{
              endAdornment: <>
                {search && <IconButton size='small' onClick={e => {
                  setPageInfo({
                    ...pageInfo, search: ''
                  })
                  setSearch('')
                }}>
                  <X></X>
                </IconButton>}
                <IconButton size='small' onClick={e => setPageInfo({
                  ...pageInfo, search
                })}>
                  <Search></Search>
                </IconButton>
              </>,
            }}
          ></TextField>
        </form>
      </Grid> </Grid>
  )
}
