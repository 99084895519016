import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { api, fileapi } from '../../Services/api';
import { reloadCreds, forceUploadSignerCreds } from '../Atoms/Login';

const sendSignedAgreement = selectorFamily({
  key: 'sendSignedAgreement',
  get: (values: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await fileapi.POST(
        '/updateAgreement',
        values
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getSignerCreds = selector({
  key: 'getSignerCreds',
  get: async ({ get }) => {
    get(reloadCreds)
    try {
      await verifyAuthToken()
      const res: any = await api.GET('signer/getSignerCreds/-1');
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const uploadSignerCreds = selectorFamily({
  key: 'uploadSignerCreds',
  get: (params: any) => async ({ get }) => {
    
    get(forceUploadSignerCreds)
    
    const data = new FormData()
    
    data.append('doc_type', params?.doc_type)
    data.append('expire_date', params?.expire_date)
    data.append('file', params?.file)

    try {
      await verifyAuthToken()
      const res: any = await fileapi.POST(
        'upload/signercreds',
        data
      );
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const updateAutoProfile = selectorFamily({
  key: 'updateAutoProfile',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT(`signer/update/autoprofile`, params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const viewDocs = selectorFamily({
  key: 'viewDocs',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await fileapi.DOWNLOAD('getfile', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

export { sendSignedAgreement, getSignerCreds, uploadSignerCreds, updateAutoProfile, viewDocs }
