import React, { useState } from 'react';
import {
  Typography,
  // Box,
  Grid,
  TextField,
  ButtonGroup,
  Button,
  Paper,
  Tabs,
  Tab,
  CircularProgress
} from '@material-ui/core';
import Box from '@mui/system/Box';
import { Alert } from '@material-ui/lab';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil'

import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { ProfileForm } from './../Components/MyProfile/ProfileForm'
import { Page } from '../Components/Page';
import { SignerCredentials } from '../Components/SignerCredentials';

import { updateAutoProfile } from './../Recoil/Selectors/Signer'
import { getProfile } from './../Recoil/Selectors/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 10
  },
  paper: {
    padding: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  removeSelectMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8
  },
  mainContainer: {
    textAlign: 'right'
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  labelTitle: {
    marginRight: 10,
    fontWeight: 'bold',
    color: 'rgb(119, 119, 119)'
  },
  pageTitle: {
    marginTop: 30,
    marginBottom: 40,
    marginLeft: 10
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  }
}));



const AutoProfileTabFields = (params: any) => {
  const classes = useStyles();
  const profileRequest: any = useRecoilValueLoadable(getProfile)
  const profile = profileRequest?.contents?.result || null
  const [lisenceplate, setLisenceplate] = useState(profile.car_license_plate_number)
  const [carmodel, setCarmodel] = useState(profile.car_model)
  const [color, setColor] = useState(profile.car_color)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(null);

  const handleSave = useRecoilCallback(({ snapshot }) => async () => {
    setLoading(true)
    const { result, success } = await snapshot.getPromise(updateAutoProfile({ 
      car_license_plate_number: lisenceplate, 
      car_model: carmodel, 
      car_color: color
     }))
     if (success) {
      setAlert({
        status: 'success',
        message: result.response.data
      });
     } else { 
      setAlert({
        status: 'error',
        message: result?.error || 'Failed to Update Auto Profile'
      });
      }
    setLoading(false)
  })

  return (
    <>
      <form className={classes.form}>
        <Grid container spacing={2} className={classes.mainContainer}>
          <Grid item xs={12}>
            <Grid container className={classes.formGroup}>
              <Grid item xs={12} sm={2} md={2}>
                <Typography variant='body2' className={classes.labelTitle}>
                  Car License Plate Number
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  id='outlined-basic'
                  label='Plate Number'
                  variant='outlined'
                  name='car_license_plate_number'
                  value={lisenceplate}
                  onChange={(e)=>{setLisenceplate(e.target.value)}}
                  size='small'
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container className={classes.formGroup}>
              <Grid item xs={12} sm={2} md={2}>
                <Typography variant='body2' className={classes.labelTitle}>
                  Car Model
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  id='outlined-basic'
                  label='Car Model'
                  variant='outlined'
                  size='small'
                  value={carmodel}
                  onChange={(e)=>{setCarmodel(e.target.value)}}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container className={classes.formGroup}>
              <Grid item xs={12} sm={2} md={2}>
                <Typography variant='body2' className={classes.labelTitle}>
                  Car Color
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  id='outlined-basic'
                  label='Car Color'
                  variant='outlined'
                  size='small'
                  value={color}
                  onChange={(e)=>{setColor(e.target.value)}}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonGroup aria-label='button group'>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ marginRight: '10px' }}
                  onClick={handleSave}
                  disabled={loading}
                >
                  {loading && <CircularProgress /> }
                  Save
                </Button>
                <Button variant='contained'>Cancel</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {alert &&
        <Box>
          <Alert severity={alert.status}>{alert.message}</Alert>
        </Box>
      }
    </>
  );
};

const ProfileTabFields = () => {
  return (
    <>
      <ProfileForm />
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const Profile = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page className={classes.root} title='Update Profile'>
      <Typography variant='h3' className={classes.pageTitle}>
        Update Profile
      </Typography>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='Profile' {...a11yProps(0)} />
          <Tab label='Credentials' {...a11yProps(1)} />
          <Tab label='Auto Profile' {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <ProfileTabFields />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <SignerCredentials></SignerCredentials>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <AutoProfileTabFields />
        </TabPanel>
      </Paper>
    </Page>
  );
};

const MyProfile = () => {
  return (
    <Profile />
  );
};

export { MyProfile };
