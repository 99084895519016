import Axios from 'axios'
import { Production } from 'src/Common/config/config'

export const getLatlngFromAddress = (address, setLatLng, defaultValue) => {

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${Production.GOOGLE_MAPS_API_KEY}`
  Axios.get(url).then(response => response.data).then(res => {
    setLatLng(res?.results?.[0]?.geometry?.location || defaultValue)
  }).catch(err => {
    console.error(err);
  })
}
