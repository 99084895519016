import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import { useStyles } from '../Styles/Fields';

const ReceiveTextMessage = ({ values, handleChange, errors, touched }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={12} className={classes.marginSides}>
      <Typography variant='body2'>
        Can You Receive or Send Text Messages?
      </Typography>
      <FormControl error={errors?.send_receive_sms && touched?.send_receive_sms}>
        <RadioGroup
          row
          aria-label='send_receive_sms'
          name='send_receive_sms'
          value={values.send_receive_sms}
          onChange={handleChange}
        >
          <FormControlLabel value={'Y'} control={<Radio />} label={'Yes'} />
          <FormControlLabel value={'N'} control={<Radio />} label={'No'} />
        </RadioGroup>
        {errors?.send_receive_sms && touched?.send_receive_sms && (
          <FormHelperText error>
            { errors.send_receive_sms}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export { ReceiveTextMessage };
