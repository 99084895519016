import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const BorrowerName = ({ location, values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant='body2'>Borrower's Name</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <TextField
          label='First Name'
          name={`${location}.w_Borrower_First_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='dense'
          value={values.w_Borrower_First_Name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Borrower_First_Name}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Borrower_First_Name)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <TextField
          label='Last Name'
          name={`${location}.w_Borrower_Last_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='dense'
          value={values.w_Borrower_Last_Name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Borrower_Last_Name}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Borrower_Last_Name)}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { BorrowerName };
