import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  TableContainer,
  Paper
} from '@material-ui/core';
import { useRecoilValueLoadable, useRecoilCallback, useSetRecoilState } from 'recoil';

import { getSignerCreds, uploadSignerCreds, viewDocs } from 'src/Recoil/Selectors/Signer';
import { reloadCreds } from 'src/Recoil/Atoms/Login';

const rows = [
  {
    title: <>W-9: Print, Complete, Sign and Upload Below
      (<a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf'
        target='_blank'
        rel='noopener noreferrer'
      >
        Click Here
      </a>  to download form)
    </>,
    docType: 'W9',
    hasDate: false,
  },
  {
    title: <>Privacy Policy: Print, Complete, Sign and Upload Below
      (<a
        href='/static/privacy_agreement.pdf'
        target='_blank'
        rel='noopener noreferrer'
      >
        Click Here
      </a>  to download )
    </>,
    docType: 'PP',
    hasDate: false,
  },
  {
    title: <>NSA Certification (<a
      href='https://www.nationalnotary.org/signing-agent/nsa'
      target='_blank'
      rel='noopener noreferrer'
    >
      Click Here</a> To Become Certified)
    </>,
    docType: 'BGCHK',
    hasDate: true,
  },
  {
    title: <>Background Check</>,
    docType: 'BGCHKNON',
    hasDate: true,
  },
  {
    title: 'Driver License',
    docType: 'DL',
    hasDate: true,
  },
  {
    title: 'Notary Commissions',
    docType: 'NC',
    hasDate: true,
  },
  {
    title: 'Notary Bond (If Applicable)',
    docType: 'NB',
    hasDate: true,
  },
  {
    title: 'Notary Error and Omission Policy (If Available)',
    docType: 'NEOP',
    hasDate: true,
  },
  {
    title: 'Attorney State Bar License (If Attorney)',
    docType: 'ASBL',
    hasDate: true,
  },
  {
    title: 'Title Insurance Producer TIP (If Applicable)',
    docType: 'TIP',
    hasDate: true,
  },
];
const SignerCredentials = ((props) => {

  const setReload = useSetRecoilState(reloadCreds)
  const refresh = () => setReload(r => r + 1)
  const [loading, setLoading] = useState('')
  const [expireDate, setExpireDate] = useState({
    DL: null,
    NC: null,
    NB: null,
    NEOP: null,
    W9: null,
    PP: null,
    ASBL: null,
    TIP: null,
    BGCHK: null,
    BGCHKNON: null,
  })

  const [files, setFiles] = useState({
    DL: null,
    NC: null,
    NB: null,
    NEOP: null,
    W9: null,
    PP: null,
    ASBL: null,
    TIP: null,
    BGCHK: null,
    BGCHKNON: null,
  })
  const [uploadingFile, setUploadingFile] = useState(null)

  const signerDocsRequest: any = useRecoilValueLoadable(getSignerCreds)
  const signerDocs = signerDocsRequest?.contents?.result?.rows || []
  const isLoadingFiles = signerDocsRequest.state === 'loading'
  const onUploadFile = useRecoilCallback(({ snapshot }) => async (docType: any) => {

    if (!files[docType]) {
      props.enqueueSnackbar(
        'Please select file',
        {
          variant: 'error'
        }
      )
    } else if (!expireDate[docType] && rows.find(r => r.docType === docType)?.hasDate) {
      props.enqueueSnackbar(
        'Please select date',
        {
          variant: 'error'
        }
      )
    } else {
      setUploadingFile(docType)
      const res = await snapshot.getPromise(uploadSignerCreds({
        doc_type: docType, expire_date: expireDate[docType], file: files[docType],
      }));
      if (res.success) {
        setUploadingFile(null)
        setExpireDate({
          ...expireDate,
          [docType]: ''
        })
        setFiles({
          ...expireDate,
          [docType]: null
        })
        refresh()
        props.enqueueSnackbar(
          res?.result?.message || 'File Uploaded',
          {
            variant: 'success'
          }
        )
      } else {
        setUploadingFile(null)
        props.enqueueSnackbar(
          res?.result?.data?.message || 'Error occurred',
          {
            variant: 'error'
          }
        )
      }
    }
  });

  const onViewDocs = useRecoilCallback(({ snapshot }) => async (filename, loadingFile: string, docType: String) => {
    setLoading(loadingFile)

    const res = await snapshot.getPromise(viewDocs({
      folder: `signers/${docType}`,
      filename,
      action: 'view/download'
    }));
    if (res.success) {
      const fileURL = URL.createObjectURL(res.result);
      setLoading('')
      window.open(fileURL);
    } else {
      props.enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body1' color='error'>
          Please scan and upload the required documents below.
        </Typography>
        <Typography variant='body1' color='error'>
          If you are unable to upload, please fax documents To (800)732-4494.
        </Typography>
        <TableContainer component={Paper}>
          {rows.map(r => (
            <Table
              className=''
              aria-label='simple table'
              style={{ marginTop: '30px' }}
              key={r.docType}
            >
              <TableHead>
                <TableRow style={{ border: '1px solid #ccc' }}>
                  <TableCell style={{ borderRight: '1px solid #ccc' }}>
                    {r?.title}:
                  </TableCell>
                  {r.hasDate && (
                    <TableCell style={{ borderRight: '1px solid #ccc' }}>
                      Expire Date
                    </TableCell>
                  )}
                  <TableCell align='right'></TableCell>
                  {/* <TableCell align='right'>Fat&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingFiles && (
                  <TableRow>
                    <TableCell
                      component={'td' as any}
                      scope='row'
                      colSpan={2}
                      style={{ textAlign: 'center' }}
                    >
                      <CircularProgress size={30}></CircularProgress>
                    </TableCell>
                  </TableRow>)}
                {!signerDocs.filter(d => d.doc_type === r.docType)?.length && (
                  <TableRow>
                    <TableCell
                      component={'td' as any}
                      scope='row'
                      colSpan={2}
                      style={{ borderRight: '1px solid #ccc' }}
                    >
                      No result found.
                    </TableCell>

                  </TableRow>
                )}
                {signerDocs.filter(d => d.doc_type === r.docType).map(doc => (
                  <TableRow style={{ border: '1px solid #ccc' }}>
                    <TableCell
                      component={'td' as any}
                      scope='row'
                      colSpan={r.hasDate ? 1 : 2}
                      style={{ borderRight: '1px solid #ccc' }}
                    >
                      <a href={`/${doc.doc_name}`} onClick={e => {
                        e.preventDefault();
                        onViewDocs(doc.doc_name,
                          r.docType.concat(doc.doc_name),
                          r.docType)
                      }}>
                        {r.docType.concat(doc.doc_name) === loading ?
                          <CircularProgress size={25}></CircularProgress>
                          : doc.doc_name
                        }
                      </a>
                    </TableCell>
                    {r.hasDate && (
                      <TableCell
                        style={{
                          borderRight: '1px solid #ccc',
                          borderLeft: '1px solid #ccc'
                        }}
                      >
                        {doc.expire_date}
                      </TableCell>
                    )}
                    {r.hasDate && <TableCell align='right'></TableCell>}
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow style={{ border: '1px solid #ccc' }}>
                  <TableCell style={{ borderRight: '1px solid #ccc' }}>
                    {files[r.docType] ? <b>{files[r.docType]?.name}</b> : <input
                      type='file'
                      onChange={e => setFiles({
                        ...files,
                        [r.docType]: e.target.files[0]
                      })}
                    />}
                  </TableCell>
                  {r.hasDate && (
                    <TableCell style={{ borderRight: '1px solid #ccc' }}>
                      <input
                        type='date'
                        value={expireDate[r.docType]}
                        onChange={e => setExpireDate({
                          ...expireDate,
                          [r.docType]: e.target.value
                        })}
                      />
                    </TableCell>
                  )}
                  <TableCell align='left'>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginRight: '10px' }}
                      onClick={e => onUploadFile(r.docType)}
                      disabled={uploadingFile === r.docType}
                    >
                      {uploadingFile === r.docType &&
                        <> <CircularProgress size={25}></CircularProgress> &nbsp; </>
                      }
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          ))}
        </TableContainer>
      </Grid>
    </Grid>
  )
})
export { SignerCredentials }
