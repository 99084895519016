import { selector, selectorFamily } from 'recoil';
import { api } from '../../Services/api';
import { refetechAllSignerUsers } from '../Atoms/Users';
import { verifyAuthToken } from './Auth'

const getAllSignerUsers = selector({
  key: 'getAllSignerUsers',
  get: async ({ get }) => {
    try {
      get(refetechAllSignerUsers)
      await verifyAuthToken()
      const res: any = await api.GET('signer/user');
      return {
        result: res,
        success: true
      }
    } catch (error) {
      return {
        result: [],
        success: false
      }
    }
  }
});

const addSignerUser  = selectorFamily({
  key: 'addSignerUser',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('signer/user', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const assignUserOrder = selectorFamily({
  key: 'assignUserOrder',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('signer/user/assign', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const deactivateUser = selectorFamily({
  key: 'deactivateUser',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('signer/user/deactivate', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

export {
  getAllSignerUsers,
  addSignerUser,
  assignUserOrder,
  deactivateUser
};
