import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const AdditionalClientEmail = ({ location, values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={3} md={2}>
        <Typography variant='body2'>Additional Confirmation Email</Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <TextField
          label='Email'
          name={`${location}.additional_client_email`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.additional_client_email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].additional_client_email}
          error={Boolean((touched[location] && errors[location]) && errors[location].additional_client_email)}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { AdditionalClientEmail };
