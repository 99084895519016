import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, makeStyles, SvgIcon, useMediaQuery } from '@material-ui/core';
import Box from '@mui/system/Box';
import clsx from 'clsx';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/ThemeComponents/components/Logo';
import { THEMES } from 'src/ThemeComponents/constants';
import type { Theme } from 'src/ThemeComponents/theme';
import Account from './Account';
import Settings from './Settings';
import Announcement from './Announcement';
import { useTheme } from '@material-ui/core/styles';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: (open) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {
          !isLgUp && <IconButton
            color='inherit'
            onClick={() => onMobileNavOpen(true)}
          >
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        }
        {
          !isMdDown && <RouterLink to='/'>
            <Logo height='50px' link='/static/White-Logo.png' />
          </RouterLink>
        }
        <Box ml={2} flexGrow={1} />
        <Settings />
        <Announcement />
        <Account />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
