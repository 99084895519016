import { api } from '../Services/api';

const checkEmailAvailablility = async (email: string) => {
  try {
    const res: any = await api.POST('auth/signer/checkEmailAvailablility', {
      email
    });
    return {
      result: res,
      success: true
    };
  } catch (err) {
    return {
      result: err,
      success: false
    };
  }
};

export { checkEmailAvailablility };
