import * as Yup from 'yup';

import { PasswordRegex } from '../Regex/Passwrod';

const Schema = Yup.object().shape({
  currentPassword:  Yup
  .string()
  .required('Password is required'),
  newPassword: Yup
    .string()
    .required('Password is required')
    .matches(
      PasswordRegex,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .matches(
        PasswordRegex,
        'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
      ),
})

export {
  Schema
};
