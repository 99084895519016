import { selector, selectorFamily } from 'recoil';
import moment from 'moment';

import { verifyAuthToken } from './Auth'
import { userInfo } from '../Atoms/Login';
import {
  pagination,
  refetchOrders,
  forceUpdateFiles,
  searchedOrders,
  refetchAvailableOrders,
  forceDownload
} from '../Atoms/Orders';
import { division, userPrivilege } from '../Atoms/Divisions';

import { api, fileapi } from '../../Services/api';

const getSignerOrders = selector({
  key: 'signerOrders',
  get: async ({ get }) => {
    try {
      get(refetchOrders);
      const { limit, page, search, orderBy, orderType } = get(pagination);
      await verifyAuthToken()
      const res: any = await api.GET(
        `orders/getSignerOrders/${limit}/${page}?search=${search}&orderBy=${orderBy}&orderType=${orderType}`
      );
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const fetchOrders = selector({
  key: 'fetchOrders',
  get: async ({ get }) => {
    get(refetchOrders);
    const user = get(userInfo);
    const divisionId = get(division);
    const privilege = get(userPrivilege);
    const { limit, page } = get(pagination);
    const search = get(searchedOrders);

    if (divisionId && privilege) {
      const date = moment()
        .subtract(90, 'days')
        .format('YYYY-MM-DD');
      try {
        const params: any = {
          limit,
          page,
          date,
          division_id: divisionId
        };

        if (privilege === 'Self') {
          params.Contact_ID = user.Id;
        }
        if (search.value !== '') {
          params.searchQuery = search;
        }
        await verifyAuthToken()
        const res: any = await api.POST('orders/orders/byDivision', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }

    return {
      result: [],
      success: false
    };
  }
});

const createOrder = selectorFamily({
  key: 'createOrder',
  get: (params: any) => async ({ get }) => {
    const user: any = get(userInfo);
    const divisionId = get(division);
    if (params) {
      params.location_one.division_id = divisionId;
      params.location_one.Contact_ID = user.Id;
      params.location_one.clientID = user.Company_ID;
      params.location_one.w_Company_Name = user.company_name;
      params.location_one.w_Requesting_Agent_E_Mail = user.Contact_Email;
      params.location_one.w_Requesting_Agent_Name =
        `${user.Contact_First_Name} ${user.Contact_Last_Name}`;
      params.location_one.w_record_state = 'New';
      params.location_one.w_creation_date = moment().format('MM/DD/YYYY');
      params.location_one.w_creation_time = moment().format('hh:mm a');
      params.location_one.w_appt_time_adj_local_formatted = moment(
        params.location_one.w_Appointment_Time
      ).format('hh:mm:ss');
      if (params.location_two) {
        params.location_two.division_id = divisionId;
        params.location_two.Contact_ID = user.Id;
        params.location_two.clientID = user.Company_ID;
        params.location_two.w_Company_Name = user.company_name;
        params.location_two.w_Requesting_Agent_E_Mail = user.Contact_Email;
        params.location_two.w_Requesting_Agent_Name =
          user.Contact_First_Name + ',' + user.Contact_Last_Name;
        params.location_two.w_record_state = 'New';
        params.location_two.w_creation_date = moment().format('MM/DD/YYYY');
        params.location_two.w_creation_time = moment().format('hh:mm a');
        params.location_two.w_appt_time_adj_local_formatted = moment(
          params.location_two.w_Appointment_Time
        ).format('hh:mm:ss');
      }
      try {
        const res: any = await api.POST('orders/orders/', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const editOrder = selectorFamily({
  key: 'editOrder',
  get: (params: any) => async () => {
    try {
      const res: any = await api.PUT(
        `orders/${params.location_one.Id}`,
        params
      );
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const uploadFile = selectorFamily({
  key: 'uploadFile',
  get: ({ file, destination }: any) => async ({ get }) => {
    try {
      get(forceUpdateFiles);
      if (file) {
        const formData: any = new FormData();
        formData.append('destination', String(destination));
        formData.append('file', file);

        await verifyAuthToken()
        const res: any = await fileapi.POST('upload', formData);
        return {
          result: res,
          success: true
        };
      }
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }

    return {
      result: false,
      success: false
    };
  }
});

const uploadScanbackAlert = selectorFamily({
  key: 'uploadScanbackAlert',
  get: (order: any) => async ({ get }) => {
    try {
      const user = get(userInfo);

      await verifyAuthToken()
      const res: any = await api.POST('client/uploadScanbackAlert', {
        signer_name: `${user.Signer_First} ${user.Signer_Last}`,
        order_number: order.f_loan_number,
        Id: order.Id,
        last_name: order.f_borrower_last_name,
        date: order.w_Appointment_Date,
        time: order.w_Appointment_Time,
        files: order.files
      });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const FetchFileList = selectorFamily({
  key: 'FetchFileList',
  get: (order: any,) => async ({ get }) => {
    if (order.Id) {
      try {
        get(forceUpdateFiles);
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id || ''}${order.folderPath || ''}`
        });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const DeleteFile = selectorFamily({
  key: 'DeleteFile',
  get: params => async ({ get }) => {
    try {
      get(forceUpdateFiles);
      await verifyAuthToken()
      const res: any = await fileapi.POST('delete', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const FetchFile = selectorFamily({
  key: 'FetchFile',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceDownload)
      await verifyAuthToken()
      const res: any = await fileapi.DOWNLOAD('getfile', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const getAvailableOrders = selector({
  key: 'getAvailableOrders',
  get: async ({ get }) => {
    try {
      get(refetchAvailableOrders);
      await verifyAuthToken()
      const res: any = await api.POST('signer/available/orders');
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const offerAction = selectorFamily({
  key: 'offerAction',
  get: (props: any) => async () => {
    const url = props.action === 'A' ? 'orders/accept' : 'orders/signers/counteroffer'
    delete props.action
    try {
      await verifyAuthToken()
      const res: any = await api.POST(url, props);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: [],
        success: false
      };
    }
  }
});
const changeOrderStatus = selectorFamily({
  key: 'changeOrderStatus',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('orders/update_status/' + params.Id, params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});
const printConfirmation = selectorFamily({
  key: 'printConfirmation',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET_DOWNLOAD(`orders/print/confirmation/${params.Id}`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const submitInvoice = selectorFamily({
  key: 'submitInvoice',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT(
        `orders/submit/invoice/${params.Id}`,
        { date: new Date().toLocaleDateString() }
      );
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const invoiceDispute = selectorFamily({
  key: 'invoiceDispute',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST(
        'orders/invoice/dispute',
        params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const viewInvoice = selectorFamily({
  key: 'viewinvoice',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET_DOWNLOAD(
        `orders/view/invoice/${params.Id}`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const sendMailToPacdoc = selectorFamily({
  key: 'sendMailToPacdoc',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('orders/send-mail-to-pacdoc', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const assignSigner = selectorFamily({
  key: 'assignSigner',
  get: ({ ...data }: any) => async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('/orders/assign', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  fetchOrders,
  createOrder,
  editOrder,
  uploadFile,
  uploadScanbackAlert,
  FetchFileList,
  DeleteFile,
  FetchFile,
  getSignerOrders,
  getAvailableOrders,
  offerAction,
  changeOrderStatus,
  printConfirmation,
  submitInvoice,
  invoiceDispute,
  viewInvoice,
  sendMailToPacdoc,
  assignSigner
};
