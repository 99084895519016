import type { Theme } from '../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(100vh - 94px)',
    backgroundColor: theme.palette.background.paper,
    margin: 15,
    padding: 15,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },PageTitleWrapper:{
    width:'100%',
    margin:'20px 0px',
    padding:'20px'
  },
  pageTitle:{
    fontSize: '20px',
    marginBottom: '0',
    marginTop: '7px',
    color: theme.palette.text.primary,
  },
  heading: {
    color: theme.palette.text.primary,
    margin: '10px 0',
    fontSize:'18px',
  },
  row:{
    display:'flex',
    alignItems:'center',
    padding:'10px'
  },
  iconRoot:{
    color: theme.palette.text.primary,
    fontSize:'16px',
  },
  contactLabel:{
    marginLeft:'10px',
    color: theme.palette.text.primary,
    fontSize:'14px',
  },
  subHeading: {
    color: theme.palette.text.primary,
    margin: '5px 0px',
    fontSize:'18px',
  },
  changePasswordCard: {
    margin: '20px',
    maxWidth:'500px'
  },
  passwordField: {
    margin:'10px 0px',
  },
  buttonField: {
    margin:'0px 10px 0px 0px',
  }
}));
