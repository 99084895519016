import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';

import { useStyles } from '../Styles/Fields';

const AppointmentDateTime = ({ location, values, errors, touched, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={5}>
        <KeyboardDatePicker
          className={classes.mt10}
          label='Appointment Date'
          name={`${location}.w_Appointment_Date`}
          margin='dense'
          format='MM/DD/YYYY'
          fullWidth
          value={values.w_Appointment_Date}
          onChange={(e) => setFieldValue(`${location}.w_Appointment_Date`, moment(e, 'MM/DD/YYYY'))}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Appointment_Date}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Appointment_Date)}
          KeyboardButtonProps={{
            'aria-label': 'Change Date',
          }}
          InputLabelProps={{
            shrink: true
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          className={classes.mt10}
          label='Open Time'
          control={
            <Checkbox
              color='primary'
              name={`${location}.w_Appointment_Time_type`}
              checked={values.w_Appointment_Time_type === 'OT'}
              onChange={(e) => setFieldValue(`${location}.w_Appointment_Time_type`, e.target.checked ? 'OT' : 'SC')}
            />
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <KeyboardTimePicker
          className={classes.mt10}
          label='Appointment Time'
          name={`${location}.w_Appointment_Time`}
          margin='dense'
          format='hh:mm A'
          fullWidth
          minutesStep={15}
          value={values.w_Appointment_Time}
          onChange={(e) => setFieldValue(`${location}.w_Appointment_Time`, moment(e, 'hh:mm A'))}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Appointment_Time}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Appointment_Time)}
          KeyboardButtonProps={{
            'aria-label': 'Change Time',
          }}
          InputLabelProps={{
            shrink: true
          }}
          disabled={values.w_Appointment_Time_type === 'OT' || disabled}
        />
      </Grid>
    </>
  );
}

export { AppointmentDateTime };
