import React from 'react';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';

import { states } from '../../../Common/States';

import { useStyles } from './../Styles/Fields'

const CommissioningStates = ({ values, errors, setFieldValue, handleChange, disabled, i }: any) => {
  
  const classes = useStyles()
  
  return (
      <Grid item xs={12} className={classes.rowMargin} >
        <FormControl
          variant='outlined'
          size='small'
          fullWidth
          error={errors.commisioning_states}
          disabled={disabled}
        >
          <InputLabel>Commisioning State</InputLabel>
          <Select
            name={`commisioning_states[${i}]`}
            label='Commisioning State'
            fullWidth
            value={values}
            onChange={handleChange}
          >
            {states.map(state => {
              return(
                <MenuItem
                  key={`commisioning_states-${i}-${state.code}`}
                  value={state.code}
                >
                  {state.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
  );
}

export { CommissioningStates };
