import React, { useState } from 'react';
import moment from 'moment-timezone'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button
} from '@material-ui/core';
import Box from '@mui/system/Box';

import { storage } from '../../Services/storage';

import { ExpandMore } from '@material-ui/icons';
import { CheckCircle } from 'react-feather'

import { DocumentUpload } from './DocumentUpload'
import { GeneralModal } from './../../Components/Modal';
import { Agreement } from 'src/Components/Agreement/Agreement';

import { useStyles } from './Styles/index';

const GeneralAccordion = () => {
  const classes = useStyles();

  let missingDocs = []
  const [agreementModal, handleAgreementModal] = useState(false);
  const [loading, setLoading] = useState(false)

  const documents = storage.get('missingDocuments')

  if (documents) {
    missingDocs = JSON.parse(documents)
  }

  const [docs, setDocs] = useState({
    W9: missingDocs.find(doc => doc.doc_type === 'W9'),
    DL: missingDocs.find(doc => doc.doc_type === 'DL'),
    NC: missingDocs.find(doc => doc.doc_type === 'NC'),
    BGCHKNON: missingDocs.find(doc => doc.doc_type === 'BGCHKNON'),
    BGCHK: missingDocs.find(doc => doc.doc_type === 'BGCHK'),
    PP: missingDocs.find(doc => doc.doc_type === 'PP')
  })

  const removeDocType = (docType) => {
    setDocs({ ...docs, [docType]: undefined })
  }

  return (
    <Box className={classes.root}>
      {missingDocs?.length === 0 &&
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
          <CheckCircle size={65} color='#357a38' />
          <Typography variant='body1'>No Missing Documents</Typography>
        </Box>
      }
      {docs.W9 &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>W-9</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentUpload expiry={false} doc_type='W9' setDocs={setDocs} removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
          </AccordionDetails>
        </Accordion>
      }

      {docs.DL &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Driver License {docs?.DL?.expired ? `(Expired on: ${moment(docs?.DL?.expire_date).format('MM/DD/YYYY')})` : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentUpload expiry={true} doc_type='DL' removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
          </AccordionDetails>
        </Accordion>
      }

      {docs.NC &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Notary Commissions {docs?.NC?.expired ? `(Expired on: ${moment(docs?.NC?.expire_date).format('MM/DD/YYYY')})` : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentUpload expiry={true} doc_type='NC' removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
          </AccordionDetails>
        </Accordion>
      }
      {docs.BGCHKNON &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Background Check {docs?.BGCHKNON?.expired ? `(Expired on: ${moment(docs?.BGCHKNON?.expire_date).format('MM/DD/YYYY')})` : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentUpload expiry={true} doc_type='BGCHKNON' removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
          </AccordionDetails>
        </Accordion>
      }
      {docs.BGCHK &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Background Check {docs?.BGCHK?.expired ? `(Expired on: ${moment(docs?.BGCHK?.expire_date).format('MM/DD/YYYY')})` : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentUpload expiry={true} doc_type='BGCHK' removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
          </AccordionDetails>
        </Accordion>
      }
      {docs?.PP &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Privacy Policy {docs?.PP?.expired ? `(Expired on: ${moment(docs?.PP?.expire_date).format('MM/DD/YYYY')})` : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleAgreementModal(true)}
            >Sign Privacy Policy
            </Button>
          </AccordionDetails>
        </Accordion>
      }

      {agreementModal && (
        <GeneralModal
          open={agreementModal}
          title={"AGREEMENT"}
          loading={false}
          openModal={() => handleAgreementModal(!agreementModal)}
          noSubmit={true}
        >
          <Agreement openModal={handleAgreementModal} removeDocType={removeDocType} loading={loading} setLoading={setLoading} />
        </GeneralModal>
      )}
    </Box>
  );
}

export default GeneralAccordion;