import { atom } from 'recoil';

const orders = atom({
  key: 'orders',
  default: []
});

const currentOrder = atom({
  key: 'currentOrder',
  default: {
    f_status_web: '',
    Id: ''
  }
});

const pagination = atom({
  key: 'pagination',
  default: {
    page: 1,
    limit: 20,
    search: '',
    orderBy: 'w_Appointment_Date',
    orderType: 'desc'
  }
});

const refetchOrders = atom({
  key: 'refetchOrders',
  default: 0
});

const forceUpdateFiles = atom({
  key: 'forceUpdateFiles',
  default: 0
});

const forceDownload = atom({
  key: 'forceDownload',
  default: 0
});

const searchedOrders = atom({
  key: 'searchedOrders',
  default: {
    field: '',
    value: ''
  }
});
const refetchAvailableOrders = atom({
  key: 'refetchAvailableOrders',
  default: 0
});

export { 
  orders, 
  currentOrder, 
  pagination, 
  refetchOrders, 
  forceUpdateFiles, 
  searchedOrders, 
  refetchAvailableOrders,
  forceDownload
};
