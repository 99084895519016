import React from 'react';
import { Grid, Typography, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

const PartialSigning = ({ location, values, errors, handleChange, disabled }) => {
  return (
    <>
      <Grid item md={4}>
        <Typography variant='body2'>Partial Signing</Typography>
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name={`${location}.Partial_Signing`}
              value={'1'}
              checked={values.Partial_Signing === '1'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name={`${location}.Partial_Signing`}
              value={'0'}
              checked={values.Partial_Signing === '0'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
      { errors[location] && errors[location].Partial_Signing &&
        <Grid item xs={12}>
          <FormHelperText error>
            {errors[location].Partial_Signing}
          </FormHelperText>
        </Grid>
      }
    </>
  );
}

export { PartialSigning };
