const config = {
  apiKey: 'AIzaSyBYDiZ4YtFVT0vQ4wFVu-Az68DDeRRWXWQ',
  authDomain: 'pacdocv2-dash.firebaseapp.com',
  databaseURL: 'https://pacdocv2-dash.firebaseio.com',
  projectId: 'pacdocv2-dash',
  storageBucket: 'pacdocv2-dash.appspot.com',
  messagingSenderId: '771664939600',
  appId: '1:771664939600:web:5932d1779e688bcc1c1f55'
};

const actionCodeSettings = {
  url: 'https://pacdoc-signers.netlify.app/login',
  // url: 'http://localhost:3000/login',
  handleCodeInApp: true,
};

export { config, actionCodeSettings };
