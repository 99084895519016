import React, { useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  CardMedia
} from '@material-ui/core';
import moment from 'moment';
import { Eye, XCircle, File, FileText } from 'react-feather';
import { enqueueSnackbar } from 'notistack'

import { OrdersTablesHead } from './OrdersTableHead';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';
import { FileUploadModal } from '../FileUpload/FileUploadModal';
import { GeneralModal } from '../Modal';

import { currentOrder, pagination } from '../../Recoil/Atoms/Orders';
import { division } from '../../Recoil/Atoms/Divisions';
import { printConfirmation, viewInvoice } from '../../Recoil/Selectors/Orders';

import { OrderChangeStatus } from './SubmitStatus';
import { ViewInvoice } from './SubmitInvoice';

import { useStyles } from './Styles/OrdersList';
import { AssignOrder } from './AssignOrder';
import { storage } from '../../Services/storage';

type Order = 'asc' | 'desc';

const OrdersList = ((props: any) => {
  const classes = useStyles();
  const { orders, total } = props;
  const completedStatuses: any = ['Signed', 'Completed', 'Cancelled']

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('w_Appointment_Date');
  const [fileModal, handleFileModal] = useState(false);
  const [mergedModel, handleMergedModel] = useState(false);

  const [printConfirmationLoader, setPrintConfirmationLoader] = useState(false);

  const [statusModal, handleStatusModal] = useState(false);
  const [assignOrderModal, handleAssignOrderModal] = useState(false);
  const [cannotSubmitStatus, handleCannotSubmitStatus] = useState(false);
  const [loadingInvoiceFile, setLoadingInvoiceFile] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const setCurrentOrder = useSetRecoilState(currentOrder);
  const [pageInfo, setPageInfo] = useRecoilState(pagination);
  const divisionID = useRecoilValue(division);
  const user = JSON.parse(storage.get('user') || "{}")
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage + 1 || 1
    });
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const getDirectionLinkFromAddress = (orderDetails: object) => {
    const signingAddressKeys = [
      'w_Signing_Location_Name',
      'w_Signing_Street_Address',
      'w_Signing_City',
      'w_Signing_State',
      'w_Signing_Zip'
    ]
    const fromAddress = user?.address || ''
    const toAddress = signingAddressKeys.map(key => orderDetails[key])
    const url = `https://www.google.com/maps/dir/${fromAddress}/${toAddress}`
    window.open(url, '_blank')
  };

  const onPrintConfirmation = useRecoilCallback(({ snapshot }) => async (Id) => {

    const res = await snapshot.getPromise(printConfirmation({
      Id
    }));
    setPrintConfirmationLoader(false)
    if (res.success) {
      const fileURL = URL.createObjectURL(res.result);
      window.open(fileURL)
      // setFile({open: true, fileURL})
    } else {
      enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });

  const onViewInvoice = useRecoilCallback(({ snapshot }) => async (Id) => {
    setLoadingInvoiceFile(Id)
    const res = await snapshot.getPromise(viewInvoice({
      Id
    }));
    if (res.success) {
      setLoadingInvoiceFile(false)
      const fileURL = URL.createObjectURL(res.result);
      window.open(fileURL)
      // setFile({open: true, fileURL})
    } else {
      setLoadingInvoiceFile(false)
      enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });

  const canSubmitInvoice = (status: string) => {
    const statuses = ['borrower would not sign', 'signed', 'completed','cancelled by client','borrower no show','late cancellation','cancelled by borrower','late cancellation','late cancelation - print', 'late cancellation - print']
    return statuses.includes(status.toLowerCase().trim())
  };

  const disableActions = (status: string) => {
    const statuses = ['borrower would not sign', 'signed', 'completed','cancelled by client','borrower no show','late cancellation','cancelled by borrower','late cancellation','late cancelation - print']
    return statuses.includes(status.toLowerCase().trim())
  };

  return (
    <div className={classes.root} hidden={props.hide}>
      {divisionID !== 0 && props.loading ?
        <PageLoad /> :
        <>
          <ViewInvoice
            open={selectedOrder}
            orderDetails={selectedOrder}
            close={() => setSelectedOrder(null)}
          />
          <OrderChangeStatus open={statusModal} handleStatusModal={handleStatusModal} />
          <AssignOrder open={assignOrderModal} handleAssignOrderModal={handleAssignOrderModal} />
          { cannotSubmitStatus &&
            <GeneralModal
              title='Cannot Update Status'
              submitLabel='OK'
              handleSubmit={() => handleCannotSubmitStatus(false)}
              openModal={() =>handleCannotSubmitStatus(false)}
              open={cannotSubmitStatus}
            >
              <XCircle size={50} color='red' />
              <Typography>
                You are attempting to submit status for an appointment before the scheduled date/time of
                the appointment. If this is intentional, please email us at info@pacdocsign.com to change
                the status to finalize documents.
              </Typography>
            </GeneralModal>
          }
          <GeneralModal
            title='Signer Confirmation'
            submitLabel='OK'
            noSubmit
            openModal={() => setFile({ open: false, fileURL: undefined })}
            open={file.open}
            maxWidth='xl'
          >
            <CardMedia
              component='iframe'
              src={`${file.fileURL}`}
              title='Signer Confirmation'
              className={classes.fullHeight}
            />
          </GeneralModal>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium'
              aria-label='enhanced table'
            >
              <OrdersTablesHead
                type={props.type}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                { props?.loading &&
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell colSpan={5}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                }
                { orders.map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.Id}>
                      <TableCell>{row.w_Loan_Number}</TableCell>
                      <TableCell>{row.f_borrower_last_name}</TableCell>
                      <TableCell>{row.w_Appointment_Date}</TableCell>
                      <TableCell>
                        {row.w_Appointment_Time_type === 'OT' ? 'Open Time' : row.w_Appointment_Time}
                      </TableCell>
                      {
                        user && user?.Signer_Account_Type === 'COMPANY' ?
                          <TableCell>
                            {
                              row.sub_signer ?
                                `${row.sub_signer.Signer_First} ${row.sub_signer.Signer_Last}`
                                : 'N/A'
                            }
                          </TableCell>
                          : ''
                      }
                      <TableCell>{row.f_status_web}</TableCell>
                      { props.type === 'available' &&
                        <TableCell>{row.distance.toFixed(2)} mil</TableCell>
                      }
                      { props.type !== 'available' &&
                        <TableCell>{row.payment_status}</TableCell>
                      }
                      { props.type !== 'available' &&
                        <TableCell>
                          {
                            !completedStatuses.includes(row?.f_status_web) &&
                            <>
                            <Typography
                              className={classes.actionLabels}
                              color='primary'
                              variant='body2'
                              onClick={e => {
                                e.stopPropagation();
                                setCurrentOrder({
                                  ...row,
                                  w_Appointment_Time: moment(
                                    row.w_Appointment_Time,
                                    'hh:mm A'
                                  )
                                });
                                handleFileModal(true);
                              }}
                            >
                            <File size='14' /> {row?.scanbackCount || 0} Scanback(s)
                            </Typography>         
                            <Typography
                              className={classes.actionLabels}
                              color='primary'
                              variant='body2'
                              onClick={e => {
                                e.stopPropagation();
                                setCurrentOrder({
                                  ...row,
                                  w_Appointment_Time: moment(
                                    row.w_Appointment_Time,
                                    'hh:mm A'
                                  )
                                });
                                handleMergedModel(true);
                              }}
                            >
                            <FileText size='14' />  {row?.documentCount || 0} Document(s)
                            </Typography>
                            </>
                          }
                        </TableCell>
                      }
                      <TableCell>
                        {
                          user && user.Signer_Account_Type === 'COMPANY' ?
                              <Typography className={classes.actionLabels}
                                          color='primary'
                                          variant='body2'
                                          onClick={e => {
                                            e.stopPropagation();
                                            setCurrentOrder({
                                              ...row,
                                              w_Appointment_Time: moment(
                                                  row.w_Appointment_Time,
                                                  'hh:mm A'
                                              )
                                            });
                                            handleAssignOrderModal(true);
                                          }}>
                                Assign Order
                              </Typography> : <></>
                        }
                        { props.type === 'available' && row.f_status_web !== 'Cancelled' ?
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                          >
                            <Tooltip title='View Order Details'>
                              <IconButton color='primary' onClick={e => {
                                e.stopPropagation();
                                props.openViewOrderDetails()
                                setCurrentOrder({
                                  ...row,
                                  w_Appointment_Time: moment(
                                    row.w_Appointment_Time,
                                    'hh:mm A'
                                  )
                                });
                              }}>
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          </Typography> :
                          <>
                            { (props.type === 'myOrders'
                              && canSubmitInvoice(row.f_status_web)
                              && row?.cansubmitinvoice && row.payment_status !== 'Invoice Submitted'
                              && row.f_status_web !== 'Cancelled' )
                              || disableActions(row.f_status_web)
                              ?
                              <Typography
                                className={classes.actionLabels}
                                color='primary'
                                variant='body2'
                                onClick={e => {
                                  e.stopPropagation();
                                  setSelectedOrder(row)
                                }}
                              >
                                Submit Invoice
                              </Typography> :
                              <>
                                { row.f_status_web !== 'Complete' &&
                                  row.f_status_web !== 'Cancelled' &&
                                  row.payment_status !== 'Invoice Submitted' &&


                                  <>
                                    <Typography
                                      className={classes.actionLabels}
                                      color='primary'
                                      variant='body2'
                                      onClick={e => {
                                        e.stopPropagation();
                                        setPrintConfirmationLoader(row.Id)
                                        onPrintConfirmation(row.Id)
                                      }}
                                    >
                                      {printConfirmationLoader === row.Id ?
                                        <CircularProgress size={25} /> :
                                        'Print Confirmation'
                                      }
                                    </Typography>
                                    <Typography
                                      className={classes.actionLabels}
                                      color='primary'
                                      variant='body2'
                                      onClick={e => {
                                        e.stopPropagation();
                                        getDirectionLinkFromAddress(row)
                                      }}
                                    >
                                      Get Directions
                                    </Typography>
                                  </>
                                }
                                { row.payment_status !== 'Invoice Submitted' && row.f_status_web !== 'Cancelled' &&
                                  <Typography
                                    className={classes.actionLabels}
                                    color='primary'
                                    variant='body2'
                                    onClick={e => {
                                      e.stopPropagation();
                                      if (moment(row.w_Appointment_Date) > moment()) {
                                        handleCannotSubmitStatus(true);
                                      } else {
                                        setCurrentOrder({
                                          ...row,
                                          w_Appointment_Time: moment(
                                            row.w_Appointment_Time,
                                            'hh:mm A'
                                          )
                                        });
                                        handleStatusModal(true)
                                      }
                                    }}
                                  >
                                    Submit Status
                                  </Typography>
                                }
                              </>
                            }
                          </>
                        }
                        { props.type === 'myOrders' && row.payment_status === 'Invoice Submitted' && (
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                            onClick={e => onViewInvoice(row.Id)}
                          >
                            {loadingInvoiceFile === row.Id ? <CircularProgress size={25}></CircularProgress> : 'View Invoice'}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            hidden={props.loading}
            rowsPerPageOptions={[10, 20, 30]}
            component='div'
            count={total}
            onPageChange={handleChangePage}
            rowsPerPage={pageInfo.limit}
            page={orders.length > 0 ? pageInfo.page - 1 : 0}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {fileModal && (
            <FileUploadModal
              open={fileModal}
              type='scanback'
              handleFileModal={handleFileModal}
              title='Scanbacks'
            />
          )}
          {mergedModel && (
            <FileUploadModal
              open={mergedModel}
              type='merge'
              handleFileModal={handleMergedModel}
              merged={true}
              title='Documents'
            />
          )}
        </>
      }
    </div>
  );
});

export { OrdersList };
