import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';

interface EnhancedTableProps {
  type: string
}

interface HeadCell {
  id: string;
  label: string;
}



const UsersTableHead = (props: EnhancedTableProps) => {
  const headCells: HeadCell[] = [
    { id: 'Signer_First', label: 'First Name' },
    { id: 'Signer_Last', label: 'Last Name' },
    { id: 'username', label: 'Username' },
    { id: 'actions', label: 'Actions' },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align='left'
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { UsersTableHead };
