import * as Yup from 'yup';

const phoneType = [
  {name: 'iPhone', value:'IP'},
  {name: 'Android', value:'A'},
  {name: 'Blackberry', value:'B'},
  {name: 'Microsoft', value:'M'},
  {name: 'Other phone with web browsers', value:'OPWWB'},
  {name: 'Phone with no internet', value:'PWNI'},
  {name: 'Other', value:'O'}
];

const languages = [
  { name: 'English', value:'ENG' },
  { name: 'Spanish', value:'SP' },
  { name: 'Chinese', value:'CH' },
  { name: 'Japanese', value:'JAP' },
  { name: 'Vietnamese', value:'VIT' },
  { name: 'Farsi', value:'FRS' },
  { name: 'Armenian', value:'ARM' },
  { name: 'Russian', value:'RUS' },
  { name: 'French', value:'FR' },
  { name: 'Hebrew', value:'HEB' },
  { name: 'Italian', value:'ITA' },
  { name: 'Tagalog', value:'FIL' },
  { name: 'Indian', value:'IND' },
  { name: 'Korean', value:'KOR' },
  { name: 'German', value:'GER' },
  { name: 'Romanian', value:'ROM' },
  { name: 'Portuguese', value:'POR' },
  { name: 'Hatian-Creloe', value:'HAT' },
  { name: 'Hindi', value:'HIN' },
  { name: 'Urdu', value:'URD' },
  { name: 'Cambodian', value:'CAM' },
  { name: 'Mandarin', value:'MAN' },
  { name: 'Taiwanese', value:'TWN' },
  { name: 'Punjabi', value:'PJB' },
  { name: 'Arabic', value:'ARB' },
  { name: 'Sign Language', value:'SIL' },
  { name: 'Other', value:'OTH' }
];

const initialValues = {
  nsa_member_number:'',
  signerType:'',
  Signer_First:'',
  Signer_Last:'',
  company_name:'',
  Signer_Status:'',
  Signer_Email:'',
  SignerNotes:'',
  commisioning_states: [],
  mobile_phone:'',
  home_phone:'',
  address: '',
  city: '',
  state: 'AL',
  zip: '',
  payment_address: '',
  payment_city: '',
  payment_state: 'AL',
  payment_zip: '',
  emergency_first_name:'',
  emergency_last_name:'',
  emergency_phone_no:'',
  send_receive_sms:'',
  prfCom: [],
  pmntAdrsSameAsAdrs: false,
  phone_type:'',
  nna_certified:'',
  fidelity_approved:'',
  set_loan_document:'',
  languages:[
    'English',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
};

const validationSchema = Yup.object().shape({
  nsa_member_number: Yup
    .string().required('NSA Number is Required.'),
  signerType: Yup
    .string().required('Signer Type is Required.'),
  Signer_First: Yup
    .string().required('First Name is Required.'),
  Signer_Last: Yup
    .string().required('Client Email Address is Required.'),
  company_name: Yup
    .string().required('Please Enter Company Name'),
  Signer_Email: Yup
    .string().email('Signer Email Address is Invalid.').required('Signer Email Address is Required.'),
  SignerNotes: Yup
    .string(),
  commisioning_states: Yup
    .array(),
  mobile_phone: Yup
    .string().required('Mobile Phone Number is Required.'),
  home_phone: Yup
    .string().required('Home Phone Number is Required.'),
  address: Yup
    .string().required('Signer Address is Required.'),
  city: Yup
    .string().required('Signer City is Required.'),
  state: Yup
    .string().required('Signer State is Required.'),
  zip: Yup
    .string().required('Signer Zip is Required.'),
  payment_address: Yup
    .string().required('Address is Required.'),
  payment_city: Yup
    .string().required('City is Required.'),
  payment_state: Yup
    .string().required('State is Required.'),
  payment_zip:  Yup
    .string().required('Zip is Required.'),
  emergency_first_name: Yup
    .string().required('Emergency Contact First Name is Required.'),
  emergency_last_name: Yup
    .string().required('Emergency Contact Last Name is Required.'),
  emergency_phone_no: Yup
    .string().required('Emergency Contact Phone Number is Required.'),
  send_receive_sms: Yup
    .string().required('Please Select Whether or Not To Receive Text Message.'),
  ConfirmationOptions: Yup
    .object().shape({
      email: Yup.boolean(),
      textMessage: Yup.boolean(),
      phone: Yup.boolean()
    }),
  languages: Yup
    .array()
    .of(Yup.string())
    .required('Language is Required')
});

export { initialValues, phoneType, languages, validationSchema };
