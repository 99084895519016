import React, { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil'
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button
} from '@material-ui/core'

import { Toast } from './../Toasts'

import { forceUploadSignerCreds } from './../../Recoil/Atoms/Login'
import { uploadSignerCreds } from './../../Recoil/Selectors/Signer'

const DocumentUpload = (props: any) => {
    
    const { expiry, doc_type, removeDocType, loading, setLoading } = props

    const [documentExpiry, setExpiry] = useState(undefined)
    const [file, setFile] = useState(undefined)
    const [toast, setTost] = useState({ open: false, type: '', message: '' })

    const setReload = useSetRecoilState(forceUploadSignerCreds)

    const onUploadFile = useRecoilCallback(({ snapshot }) => async () => {
        
        setLoading(true)
        setReload(n => n + 1)
        
        if (!file) {
            setTost({ open: true, type: 'error', message: 'Please Select a File' })
        }
        else if (expiry && !documentExpiry) {
            setTost({ open: true, type: 'error', message: 'Please Select Expiry Date' })
        } else {
            
            const res = await snapshot.getPromise(uploadSignerCreds({
                doc_type, expire_date: documentExpiry, file,
            }))

            if (res.success) {
                removeDocType(doc_type)
                setTost({ open: true, type: 'success', message: 'Document Added Successfully' })
            } else {
                setTost({ open: true, type: 'error', message: 'Failed To Add Document' })
            }
        }
        setLoading(false)

    });

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Select Document</TableCell>
                        <TableCell>Expiry</TableCell>
                        <TableCell>Save</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableCell>
                        <input
                            type='file'
                            onChange={e => setFile(e.target.files[0])}
                        />
                    </TableCell>
                    <TableCell>
                        {expiry &&
                            <input
                                type='date'
                                value={documentExpiry}
                                onChange={e => setExpiry(e.target.value)}
                            />
                        }
                    </TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={onUploadFile}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </TableCell>
                </TableBody>
            </Table>
            <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setTost} />
        </TableContainer>
    )
}

export { DocumentUpload }