import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      flexGrow: 1
    },
    addressItem: {
      color: '#000',
      padding: '7px 8px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7'
      }
    },
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    },
    selectWidth: {
      width:'100%',
    },
    signerNotes: {
      width:'100%',
      maxWidth:'100%',
      height:'100px',
      background: theme.palette.background.default,
      color:theme.palette.text.primary,
      border:'1px solid #00000050',
      padding:'5px',
      outline:'none',
      borderRadius:'3px'
    },
  searchFields: {
    margin:'5px',
    flex:'1'
  },
  searchContainer: {
    margin:'10px 0px'
  },
  searchSelect: {
    width:'100%'
  },
  searchButtons: {
    margin:'5px',
  },
  rowMargin: {
    margin:'5px 0px'
  },
  buttonLink: {
    textDecoration:'none'
  },
  overflowTitle: {
    overflow:'hidden',
    whiteSpace:'nowrap',
  },
  labelTextSize: {
    fontSize:'12px'
  },
  marginSides: {
    margin: '0px 2px'
  },
  [theme.breakpoints.down('sm')]: {
    searchButtons: {
      flex:'1'
    },
  }
  })
);

export { useStyles };
