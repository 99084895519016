import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const HomePhone = ({ values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
      <Grid item xs={12} md={6}>
        { disabled ?
          <TextField
            label='Home Phone'
            name='home_phone'
            variant='outlined'
            size='small'
            fullWidth
            value={values.home_phone}
            disabled={disabled}
          /> :
          <InputMask
            mask='(999) 999-9999'
            maskChar=''
            value={values.home_phone}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {() =>
              <TextField
                label='Home Phone'
                name='home_phone'
                variant='outlined'
                size='small'
                fullWidth
                helperText={touched.home_phone && errors.home_phone}
                error={Boolean(touched.home_phone && errors.home_phone)}
              />
            }
          </InputMask>
        }
      </Grid>
  );
}

export { HomePhone };
