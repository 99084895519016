import React from 'react'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { Typography, Grid, Card, CardContent, CircularProgress, SvgIcon, Tooltip } from '@material-ui/core';
import { UploadCloud } from 'react-feather';
import Box from '@mui/system/Box';
import { useDropzone } from 'react-dropzone';

import { uploadScanbackAlert } from '../../Recoil/Selectors/Orders';
import { currentOrder, refetchOrders } from '../../Recoil/Atoms/Orders';

import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';

const Filedropzone = ({ setSelectedFiles, selectedFiles, uploadRequest, Id }) => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const updateOrders = useSetRecoilState(refetchOrders)

  const selectedOrder: any = useRecoilValue(currentOrder);

  const sendDocumentAlert = useRecoilCallback(({ snapshot }) => async (acceptedFiles: any) => {
    await snapshot.getPromise(uploadScanbackAlert({ ...selectedOrder, files: acceptedFiles }));
  })

  const onDrop = async (acceptedFiles) => {
    for await ( let [index, file] of acceptedFiles.entries() ) {
      setSelectedFiles(acceptedFiles);
      const res = await uploadRequest(file);
      acceptedFiles[index].finished = true
      acceptedFiles[index].hasError = !res.success
      setSelectedFiles(acceptedFiles);
    }
    updateOrders(n => ++n)
    sendDocumentAlert(acceptedFiles)
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <Grid
        className={classes.fileDropzoneWrapper}
        direction='column'
        container
        {...getRootProps()}
      >
        <input className={classes.fileDropInput} {...getInputProps()} />
        { isDragActive ?
          <Typography align='center'>Drop the file here ...</Typography> :
          <>
            <SvgIcon fontSize='large' color='primary'>
              <UploadCloud />
            </SvgIcon>
            <Typography variant='h5' color='primary'>Browse Computer</Typography>
            <Typography variant='h4'>Drop Files here to upload.</Typography>
          </>
        }
      </Grid>

      <div className={classes.selectedFilesWrapper}>
      {
        selectedFiles.map((file) => {
          return (
            <Card className={classes.fileCard}>
              <CardContent className={`${classes.cardContent} ${displayClasses.flexRowCC}`}>
                {!file.finished 
                  ?
                  <Box padding={2} display='flex' alignItems='center'flexDirection='column'>
                    <Typography variant='body2'>{file.path}</Typography>
                    <CircularProgress size={12} />
                  </Box>
                  :
                  <Tooltip title={file.path}>
                    <Typography variant='body2' color={file.hasError ? 'error' : undefined}>
                      {file.path}
                    </Typography>
                  </Tooltip>
                }
              </CardContent>
            </Card>
          )
        })
        }
      </div>
    </>
  );
}

export { Filedropzone };
