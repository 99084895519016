import React, { useState } from 'react'
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Button, Dialog, DialogActions, DialogContent, Typography, Grid, CardMedia } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Box from '@mui/system/Box';

import { Filedropzone } from './Filedropzone';
import { FileList } from './FileList';

import { uploadFile, FetchFileList, DeleteFile, FetchFile } from '../../Recoil/Selectors/Orders';
import { forceUpdateFiles, currentOrder, refetchOrders, forceDownload } from '../../Recoil/Atoms/Orders';
import { GeneralModal } from './../Modal'

import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';

const FileUploadModal = ({ open, handleFileModal, type, merged = false, title = 'Upload File' }) => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(undefined)
  const todoUpdates = useSetRecoilState(forceUpdateFiles);
  const redownload = useSetRecoilState(forceDownload);
  const selectedOrder: any = useRecoilValue(currentOrder);
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const updateOrders = useSetRecoilState(refetchOrders)
  const folderPath = type === 'scanback' ? '/signers/scanbacks' : '/signers'

  const forceUpdate = () => todoUpdates((n) => n + 1);

  const uploadedFiles: any = useRecoilValueLoadable(
    FetchFileList({ ...selectedOrder, folderPath: merged ? '/signers' : folderPath })
  );
  const files = uploadedFiles?.contents?.result?.data || [];
  const total = files.length;

  const uploadRequest = useRecoilCallback(({ snapshot }) => async (
    file: any
  ) => {
    setAlert(null);
    const res: any = await snapshot.getPromise(uploadFile({ file, destination: `borrower/${selectedOrder.Id}` }));
    forceUpdate()
    if (!res.success) {
      setAlert({
        status: 'warning',
        message: 'Upload Failed.'
      });
    }
    return res
  })

  const removeFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    await snapshot.getPromise(DeleteFile({
      folder: `borrower/${selectedOrder.Id}${folderPath}`, filename
    }));
    updateOrders(n => ++n)
    forceUpdate();
  });

  const getFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    setLoading(filename)
    const newTab=window.open()
    const res: any = await snapshot.getPromise(
      FetchFile({
        folder:
          `borrower/${selectedOrder.Id}${folderPath}`, filename, action: 'view/download', orderid: selectedOrder.Id
      })
    );
    redownload( n => n + 1 )
    // forceUpdate();
    setLoading(undefined)
    if (res.success) {
      const fileURL = URL.createObjectURL(res.result);
      newTab.location.href=fileURL
    }
  })

  return (
    <>
    <Dialog
      open={open}
      maxWidth='md'
      fullWidth={true}
      onClose={() => {
        handleFileModal(false)
      }}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='h4'>{title}</Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          { !merged &&
            <Filedropzone
              selectedFiles={selectedFiles}
              uploadRequest={uploadRequest}
              Id={selectedOrder.Id}
              setSelectedFiles={setSelectedFiles}
            />
          }
          {alert &&
            <Box className={displayClasses.w100} my={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <FileList
            uploadedFiles={uploadedFiles}
            total={total}
            files={files}
            getFile={getFile}
            removeFile={removeFile}
            merged={merged}
            loading={loading}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color='secondary'
          onClick={() => {
            setSelectedFiles([])
            handleFileModal(false)
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>

    <GeneralModal
      title='File Viewer'
      submitLabel='OK'
      noSubmit
      openModal={() => setFile({ open: false, fileURL: undefined })}
      open={file.open}
      maxWidth='xl'
      fullScreen={true}
    >
      <CardMedia
        component='iframe'
        src={`${file.fileURL}`} // #toolbar=0
        title='Signer Confirmation'
        className={classes.fullHeight}
      />
    </GeneralModal>
    </>
  );
}

export { FileUploadModal };
