import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiStepIcon-active": { color: "#1769aa" },
      "& .MuiStepIcon-completed": { color: "#357a38" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#aa2e25" },
      [theme.breakpoints.down('md')]: {
        padding: '1px',
      },
    },
    container: {
      margin: '3% 12% 3% 8%',
      [theme.breakpoints.down('md')]: {
        margin: '3% 1% 3% 1%',
      },
    },
    content: {
      margin: '3% 0%',
    },
    stepperButtonSet: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    stepperButton: {
      margin: '1%',
    },
    stepperVideo: {
      height: '480px'
    }
  })
);
