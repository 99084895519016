import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import { useStyles } from './Styles/Map'

function MapContainer(props) {
  const classes = useStyles()
  const mapStyles = {
    width: '100%',
    height: '400px'
  };
  const { orderLocation, signerLocation } = props

  return (
    <div className={classes.root}>
      <Map
        google={props.google}
        zoom={5}
        style={mapStyles}
        initialCenter={
          orderLocation
        }
       />
        <Marker position={signerLocation} title='Signer'></Marker>
        <Marker position={orderLocation} title='Order'></Marker>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
