import React from 'react';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const SignerType = ({ values, handleChange }) => {
  return (
        <>
          <Grid item sm={12} xs={12} md={2}  lg={2}>
            <Typography variant='body2'>Signer Type</Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={4}  lg={4}>
            <RadioGroup row aria-label="signer_type" name="signer_type" value={values.signer_type} onChange={handleChange}>
              <FormControlLabel value="Notary" control={<Radio />} label="Notary" />
              <FormControlLabel value="Attorney" control={<Radio />} label="Attorney" />
            </RadioGroup>
          </Grid>
        </>
  );
}

export { SignerType };
