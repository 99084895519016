import React, { useState } from 'react';
import {
  useRecoilState,
  useRecoilCallback,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { FormControl, MenuItem, InputLabel, Select, CircularProgress } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack'

import { GeneralModal } from '../Modal';

import { currentOrder, refetchOrders } from '../../Recoil/Atoms/Orders';
import { assignUserOrder } from '../../Recoil/Selectors/Users';
import { getAllSignerUsers } from '../../Recoil/Selectors/Users';
import { assignSigner } from '../../Recoil/Selectors/Orders';

export const AssignOrder = ((props: any) => {
  const { handleAssignOrderModal, open } = props
  const [userId, setUserId] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [order]: any = useRecoilState(currentOrder);
  const callRefetch = useSetRecoilState(refetchOrders);

  const users: any = useRecoilValueLoadable(getAllSignerUsers)

  const usersList = users?.contents?.result;

  const refetch = () => callRefetch(n => n + 1);

  const updateStatus = useRecoilCallback(({ snapshot }) => async () => {
    setLoader(true);
    if (window.location.pathname === '/available-orders') {
      const response = await snapshot.getPromise(assignSigner({
        f_signer_id: userId,
        comment: {},
        result: {},
        fees: [],
        Id: order.Id,
      }));
      if (response.success) {
        refetch()
        handleAssignOrderModal(false)
        enqueueSnackbar(
          'Order Assignment Updated',
          {
            variant: 'success'
          }
        )
        setLoader(false);
      } else {
        enqueueSnackbar(
          response?.result?.data?.message || 'Error occurred',
          {
            variant: 'error'
          }
        )
        setLoader(false);
      }
    } else {
      const res = await snapshot.getPromise(assignUserOrder({
        order_id: order.Id,
        signer_id: userId
      }));
      if (res.success) {
        refetch()
        handleAssignOrderModal(false)
        enqueueSnackbar(
          'Order Assignment Updated',
          {
            variant: 'success'
          }
        )
        setLoader(false);
      } else {
        enqueueSnackbar(
          res?.result?.data?.message || 'Error occurred',
          {
            variant: 'error'
          }
        )
        setLoader(false);
      }
    }
  });

  const onChangeStatus = e => {
    setUserId(e.target.value);
  }
  return (
    <>
      <GeneralModal
        title='Assign Order'
        submitLabel='Submit'
        handleSubmit={updateStatus}
        openModal={e => handleAssignOrderModal(false)}
        open={open}
      >
        <FormControl
          fullWidth
          variant='outlined'
          size='small'
        >
          <InputLabel>Assign User</InputLabel>
          <Select label='Clients' onChange={onChangeStatus}>
            {usersList?.map(s =>
              <MenuItem key={s.signer_id}
                        value={s.signer_id}>
                {s.Signer_First} {s.Signer_Last}
              </MenuItem>)}
          </Select>
          {
            loader ?
              <CircularProgress style={{marginTop: '2%',marginLeft: '48%'}} size={25}/>
              : <></>
          }

        </FormControl>
      </GeneralModal>
    </>
  );
});
