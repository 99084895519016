import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Container, Divider, Typography, Link, SvgIcon } from '@material-ui/core';
import { Lock } from 'react-feather';
import Box from '@mui/system/Box';


import { Page } from '../Components/Page';
import { EmailForm } from '../Components/Login/EmailForm';

import { useStyles } from './Styles/Login';

interface HandleEmail {
  submitEmail: (params) => Promise<void>
};

const Login: FC<HandleEmail> = (props) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Login'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
          <CardContent className={classes.cardContent}>
            <div className={classes.currentMethodIcon}>
              <img alt='LOGO' src='/static/Logo.png' />
            </div>
            <Box alignItems='center' display='flex' justifyContent='space-between'>
              <div>
                <Typography color='textPrimary' gutterBottom variant='h2'>
                  SIGNER LOGIN
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  Enter your registered email address
                </Typography>
              </div>
            </Box>
            <Box my={3}>
              <EmailForm submitEmail={props.submitEmail} />
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <Link
                variant='body2'
                color='textPrimary'
                component={RouterLink as any}
                to='/register'
              >
                Register as New User
              </Link>
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Typography variant='body2' color='textSecondary' align='justify'>
              <SvgIcon className={classes.bannerChip} fontSize='small' color='primary'>
                <Lock />
              </SvgIcon>
              PacDocSign uses 128-bit Secure Sockets Layer (SSL) technology. This means that when you are on our secured
              website the data transferred between PacDocSign and you is encrypted and cannot be viewed by any
              other party. The security of your Borrower personal information is of the utmost importance to PacDocSign.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { Login };
