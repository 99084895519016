import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilState, useRecoilCallback } from 'recoil';
import { Menu, MenuItem, Typography, makeStyles, SvgIcon, Tooltip, Badge, IconButton } from '@material-ui/core';
import { User } from 'react-feather';

import { userInfo } from '../../Recoil/Atoms/Login';
import { logout } from '../../Recoil/Selectors/Auth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [user, setUser] = useRecoilState(userInfo);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = useRecoilCallback(({ snapshot }) => async () => {
    const res = await snapshot.getPromise(logout);
    if (res.success) {
      setUser(null);
      window.location.reload()
    }
  });

  return (
    <>
      <Tooltip title='Account'>
        <Badge color='secondary' variant='dot' invisible={true}>
          <IconButton color='inherit' ref={ref} onClick={handleOpen}>
            <SvgIcon>
              <User />
            </SvgIcon>
            <Typography variant='h6' color='inherit'>
              {user.Signer_First} {user.Signer_Last}
            </Typography>
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink as any}
          to='/my-profile'
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
