import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'src/ThemeComponents/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal'
    },
  })
);
