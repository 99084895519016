import React from 'react';
import { Typography } from '@material-ui/core';

const EmailText = () => {
  return (
    <>
      <Typography variant='body1'>Thank you for registering with us.</Typography>
      <br></br>
      <Typography variant='body1'>
        A member of our staff will contact you to finish your registration.
      </Typography>
      <br></br>
      <br></br>
      <Typography variant='body1'>Thank you,</Typography>
      <br></br>
      <Typography variant='body1'>PacDocSign Team</Typography>
    </>
  );
}

export { EmailText };
