import React, { useState, useRef } from 'react';
import type { FC } from 'react';
import {
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Badge,
  makeStyles
} from '@material-ui/core';
import Box from '@mui/system/Box';

import { capitalCase } from 'change-case';
import { Settings as SettingsIcon } from 'react-feather';

import useSettings from 'src/ThemeComponents/hooks/useSettings';
import { THEMES } from 'src/ThemeComponents/constants';
import type { Theme } from 'src/ThemeComponents/theme';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='Settings'>
        <Badge color='secondary' variant='dot' invisible={true}>
          <IconButton color='inherit' onClick={handleOpen} ref={ref}>
            <SvgIcon>
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant='h4' color='textPrimary'>
          Settings
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label='Theme'
            name='theme'
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant='outlined'
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
