import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import { DashboardLayout } from '../Layout';
import { BasePage } from './BasePage';
import { ResetPassword } from '../Pages/ResetPassword';

import { RequestResetPassword } from '../Pages/RequestPasswordReset'
import { Login } from '../Pages/Login';
import { LoginPassword } from '../Pages/LoginPassword';
import { Register } from '../Pages/Register';

import { storage } from './../Services/storage';

import { PageLoad } from '../Components/LoadingScreen/PageLoad';
import {
  checkAuthType,
  sendEmail,
  verifyEmail,
  loginWithPassword,
  loginWithoutPassword,
  refreshAuthToken
} from '../Recoil/Selectors/Auth';
import {
  twoFactorLogin,
  userInfo,
  forceLogin,
  missingDocsModal,
  missingPPModal
} from '../Recoil/Atoms/Login';
import { MasterLogin } from "../Pages/MasterLogin";

const RenderRoutes = () => {
  const [type, setType] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [refresh] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const navType = useNavigationType();
  const [user, setUser] = useRecoilState(userInfo);
  const handleTwoFactorLogin = useSetRecoilState(twoFactorLogin);
  const firebaseUser = useRecoilValueLoadable(verifyEmail(location?.search));

  const mysqlUser = useRecoilValueLoadable(loginWithoutPassword);
  const refreshToken = useRecoilValueLoadable(refreshAuthToken);

  const setForceLoginState = useSetRecoilState(forceLogin);
  const forceUpdate = () => setForceLoginState(n => n + 1);

  const setMissingDocsModal = useSetRecoilState(missingDocsModal);
  const setPPModal = useSetRecoilState(missingPPModal);

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user?.isSuperUser !== '1' && !user.password_reset
        && window.location.pathname !== '/change-password'
        && user.Signer_Account_Type !== 'COMPANY') {
        navigate('/change-password');
      }
    }
  });

  const setMissingDocs = () => {

    const docs = storage.get('missingDocuments')
    let missingDocs = []

    if (docs) {
      missingDocs = JSON.parse(docs)
    }

    const PP = missingDocs.find(doc => doc.doc_type === 'PP')

    if (missingDocs.length > 0) {
      setMissingDocsModal(true)
    }

    if (PP) {
      setPPModal(true)
    }
  }

  const submitEmail = useRecoilCallback(
    ({ snapshot }) => async ({ email, setSubmitting, resetForm, setAlert }) => {
      const response = await snapshot.getPromise(checkAuthType(email));
      forceUpdate();
      setType(null);
      if (response.success) {
        if (response.result.auth_type === 'two-factor') {
          await snapshot.getPromise(sendEmail(email));
          setAlert({
            status: 'info',
            message:
              'An email has been sent to this email address, please open the link in the email'
          });
        } else {
          setUserEmail(email);
        }
        resetForm();
        setSubmitting(false);
        setType(response.result.auth_type);
      } else {
        setSubmitting(false);
        setAlert({
          status: 'error',
          message: 'Invalid Email'
        });
      }
    }
  );

  const submitPassword = useRecoilCallback(
    ({ snapshot }) => async ({
      password,
      setSubmitting,
      resetForm,
      setAlert
    }) => {
      const response = await snapshot.getPromise(
        loginWithPassword({
          email: userEmail,
          password
        })
      );
      setSubmitting(false);
      if (response.success) {
        setMissingDocs()
        resetForm();
        setUser(response.result.user);
        setType(null)
        if (response.result.user?.password_reset === false && response.result.user?.isSuperUser !== '1') {
          navigate('/change-password');
        } else {
          navigate('/orders');
        }
      } else {
        setAlert({
          status: 'error',
          message: 'Invalid Password'
        });
      }
    }
  );

  useEffect(() => {
    if (type === 'password') {
      navigate('/login-password');
    }
  }, [type, navigate]);

  useEffect(() => {
    if (firebaseUser.state === 'hasValue' && firebaseUser?.contents?.success) {
      handleTwoFactorLogin(1);
    }
  }, [firebaseUser, handleTwoFactorLogin]);

  useEffect(() => {
    if (mysqlUser.state !== 'loading' && mysqlUser.contents?.success) {
      setUser(mysqlUser.contents.result.user);
      setMissingDocs()
      // history.push('/')
    }
    // eslint-disable-next-line
  }, [mysqlUser, setUser]);

  useEffect(() => {
    if (refreshToken.contents && refreshToken.contents.success === false) {
      setUser(null);
      window.location.reload()
    }
  }, [refreshToken, setUser]);
  if (refresh && ((refreshToken.state === 'loading' &&
    location?.pathname !== '/login' &&
    location?.pathname !== '/login-password') ||
    ((firebaseUser.state === 'loading' || mysqlUser.state === 'loading') &&
      location?.pathname === '/login'))) {
    return <PageLoad />
  }
  return (
    <Routes>
      <Route
        path="*"
        element={
          user ? (
            <DashboardLayout>
              {/* <Route path="master-login/:token" element={<MasterLogin />} />  */}
              <BasePage />
            </DashboardLayout>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/login" element={<Login submitEmail={submitEmail} />} />
      <Route path="/master-login/:token" element={<MasterLogin />} />
      <Route path="/resetpassword" element={<RequestResetPassword />} />
      <Route
        path="/reset-password/:token"
        element={<ResetPassword />}
      />
      <Route path="/register" element={<Register />} />
      {type === 'password' && (
        <Route
          path="/login-password"
          element={
            <LoginPassword submitPassword={submitPassword} />
          }
        />
      )}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default (RenderRoutes);
