import React, { useEffect, useState } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { Page } from '../Components/Page';
import { GeneralModal } from 'src/Components/Modal';
import { OrdersList } from 'src/Components/Orders/OrdersList';
import { ViewOrderDetails } from 'src/Components/Orders/ViewOrderDetails';
import { orderChannel } from './../Common/NotificationService'

import { refetchAvailableOrders } from './../Recoil/Atoms/Orders'
import { getAvailableOrders } from 'src/Recoil/Selectors/Orders';

import { useStyles } from './Styles/Page';


const AvailableOrders = () => {
  const classes = useStyles();

  const [ordersList, setOrdersList] = useState([])
  const [total, setTotal] = useState(0)
  const [stamp, setStamp] = useState(undefined)
  const [viewOrderDetails, setViewOrderDetails] = useState(false)
  const ordersData: any = useRecoilValueLoadable(getAvailableOrders);
  const viewOrderPorps = { closeOrderDetails: () => setViewOrderDetails(false) }
  const updateAvailableOrders = useSetRecoilState(refetchAvailableOrders)
  

  useEffect(()=> {
    if (ordersData.state !== 'loading'){
      setOrdersList(ordersData?.contents?.result?.rows || [])
      setTotal(ordersData?.contents?.result?.count)
    }
  }, [ordersData.state, setOrdersList, ordersData])

  orderChannel.bind('signer-orders', data => {
    if (stamp !== data.stamp){
      setStamp(data.stamp)
      updateAvailableOrders(n => ++n)
    }
  });
  return (
    <Page className={classes.root} title='Orders'>
      <GeneralModal
        maxWidth='sm'
        title='View Order Details'
        noSubmit
        open={viewOrderDetails}
        openModal={setViewOrderDetails}>
        <ViewOrderDetails {...viewOrderPorps}></ViewOrderDetails>
      </GeneralModal>
      <OrdersList
        openViewOrderDetails={e => setViewOrderDetails(true)}
        type='available'
        orders={ordersList}
        total={total}
        loading={ordersData?.state === 'loading' && ordersList.length === 0}
      />
    </Page>
  );
};

export default AvailableOrders;
