
import React, { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilCallback, useSetRecoilState, useRecoilValueLoadable } from 'recoil'
import { Typography, Grid, DialogContentText, Button, TextField, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import Box from '@mui/system/Box';

// "babel-loader": "^8.1.0",
import Map from './Map'
import { GeneralModal } from '../Modal'
import { getLatlngFromAddress } from 'src/Utils/Helpers'
import { currentOrder, refetchAvailableOrders, refetchOrders } from 'src/Recoil/Atoms/Orders'
import { offerAction, getSignerOrders } from 'src/Recoil/Selectors/Orders'
import { storage } from '../../Services/storage';

import { marginStyles } from 'src/Common/Styles/Margins'

import { ArrowUpCircle, ArrowRightCircle } from 'react-feather'

const ViewOrderDetails = ((props: any) => {
  const marginClasses = marginStyles()
  const orderDetails: any = useRecoilValue(currentOrder)
  const user: any = JSON.parse(storage.get('user') || "{}")
  const [openModal, setOpenModal] = useState('')
  const [loading, setLoading] = useState('')

  const { closeOrderDetails } = props

  const {
    Id,
    w_Appointment_Time,
    w_Appointment_Date,
    w_Signing_Street_Address,
    w_Signing_Zip,
    w_Signing_State,
    w_Loan_Number,
    f_signer_fees,
    latitude,
    longitude,
    w_Borrower_Last_Name,
    f_appointment_date,
    w_Appointment_Time_type
  } = orderDetails
  const {
    Signer_First,
    Signer_Last,
    signer_id,
    signer_type,
    address
  } = user

  // eslint-disable-next-line
  const myOrders = useRecoilValueLoadable(getSignerOrders)

  const [counterOfferPrize, setCounterOfferPrize] = useState(f_signer_fees)
  const [latlng, setLatLng] = useState({
    lat: latitude,
    lng: longitude
  })
  const callRefetch = useSetRecoilState(refetchAvailableOrders);
  const refetch = () => callRefetch(n => n + 1);


  const callRefetchOrders = useSetRecoilState(refetchOrders);
  const refetchMyOrders = () => callRefetchOrders(n => n + 1);

  const latlngFromAddress = () => {
    const combinedAddress = `${w_Signing_Street_Address || ''} ${w_Signing_Zip || ''} ${w_Signing_State || ''}`
    getLatlngFromAddress(combinedAddress, setLatLng, latlng)
  }

  useEffect(() => {
    if (!latlng.lat || !latlng.lng) {
      latlngFromAddress()
    }
    // eslint-disable-next-line
  }, [latlng])

  const onOfferAction = useRecoilCallback(({ snapshot }) => async () => {
    setLoading(openModal)
    setOpenModal('')
    const payload = openModal === 'A' ? { Id, w_Loan_Number, action: openModal } : {
      requested_fee: counterOfferPrize,
      w_Borrower_Last_Name,
      Signer_First,
      Signer_Last,
      signer_id,
      signer_type,
      offering_fee: f_signer_fees,
      w_Loan_Number,
      w_Appointment_Date: f_appointment_date,
      w_Appointment_Time: moment(w_Appointment_Time).format('hh:mm A'),
      address,
      action: openModal
    }

    const res: any = await snapshot.getPromise(offerAction(payload));
    setLoading('')

    if (res.success) {
      closeOrderDetails()
      refetch()
      if (openModal === 'A') {
        refetchMyOrders()
      }
      props.enqueueSnackbar(
        res.result.data || (openModal === 'A' ? 'Order accepted successfully' : 'Counter request sent successfully'),
        {
          variant: 'info',
          autoHideDuration: 15000
        }
      )
    }
    else {
      props.enqueueSnackbar(
        res.result.message || 'Error occurred!',
        {
          variant: 'error'
        }
      )
    }
  })

  const titles = {
    A: 'Accept',
    NA: 'Not Available',
    CO: 'Counter Offer'
  }

  const viewOnlyFields = [
    <Grid container item sm={12}>
      <Grid item md={3}>
        <b>Loan/Order #</b>
      </Grid>
      <Grid item md={9}>
        {w_Loan_Number}
      </Grid>
    </Grid>,
    <Grid container item sm={12}>
      <Grid item md={3}>
        <b>Appointment Time:</b>
      </Grid>
      <Grid item md={9}>
        {w_Appointment_Date} { w_Appointment_Time_type === 'OT' ? 'Open Time' : moment(w_Appointment_Time).format('hh:mm A')}
      </Grid>
    </Grid>,
    <Grid container item sm={12}>
      <Grid item md={3}>
        <b>Address:</b>
      </Grid>
      <Grid item md={9}>
        {w_Signing_Street_Address} {w_Signing_Zip} {w_Signing_State}
      </Grid>
    </Grid>,
    <Grid container item sm={12}>
      <Grid item md={3}>
        <b>Offering Fee:</b>
      </Grid>
      <Grid item md={9}>
        {f_signer_fees}
      </Grid>
    </Grid>
  ]

  return (
    <Box>
      <GeneralModal
        handleSubmit={onOfferAction}
        openModal={e => setOpenModal('')}
        open={openModal}
        submitLabel='Submit'
        title={titles[openModal]}
        loading={loading}
      >
        <Grid container spacing={2}>
          {viewOnlyFields}
          {openModal === 'CO' && (
            <Grid item>
              <br></br>
              <Typography variant='subtitle2'>Please enter your counter offer amount below:</Typography>
              <br></br>
              <TextField
                value={counterOfferPrize}
                size='small' onChange={e => setCounterOfferPrize(e.target.value)}
                type='number'
                variant='outlined'
                label='Offering fee'
              >
              </TextField>
            </Grid>
          )}
        </Grid>
      </GeneralModal>
      <DialogContentText>
        <Typography variant='h4'>
          Dear {user.Signer_First} {user.Signer_Last}
        </Typography>
        <br></br>
        <Typography variant='body2'>
          If you are intrested in accepting the following signing request,
          please choose from one of the options below and our team will contact you for order confirmation
        </Typography>
        <br></br>
        <Grid container spacing={2}>
          {viewOnlyFields}
          <Grid xs={12} item >
            <Button
              color='primary'
              variant='contained'
              className={marginClasses.mr2}
              onClick={e => setOpenModal('A')}
              disabled={Boolean(loading)}
            >
              {loading === 'A' ?
                <CircularProgress size={15} className={marginClasses.mr1} ></CircularProgress>
                : <ArrowRightCircle size={15} className={marginClasses.mr1}></ArrowRightCircle>}

              Accept
              </Button>
            <Button
              color='secondary'
              variant='contained'
              onClick={e => setOpenModal('CO')}
              disabled={Boolean(loading)}
            >
              {loading === 'CO' ?
                <CircularProgress size={15} className={marginClasses.mr1} ></CircularProgress>
                : <ArrowUpCircle size={15} className={marginClasses.mr1}></ArrowUpCircle>}

              Counter Offer
            </Button>
          </Grid>
          <Grid xs={12} item container justify='space-around'>
            <Grid item xs={12}>
              <Map
                signerLocation={{
                  lat: user.latitude,
                  lng: user.longitude
                }}
                orderLocation={latlng}
              />
            </Grid>

          </Grid>
        </Grid>
      </DialogContentText>
    </Box >
  )
})

export { ViewOrderDetails }
