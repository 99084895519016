import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const SignerEmail = ({ values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        label='Email'
        name='Signer_Email'
        variant='outlined'
        size='small'
        fullWidth
        value={values.Signer_Email}
        // onChange={handleChange}
        // onBlur={handleBlur}
        // helperText={touched.Signer_Email && errors.Signer_Email}
        // error={Boolean(touched.Signer_Email && errors.Signer_Email)}
        disabled={true}
      />
    </Grid>
  );
}

export { SignerEmail };
