import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  Signer_First: Yup.string().required('Please Enter Your First Name'),
  Signer_Last: Yup.string()
    .max(255)
    .required('Please Enter Your Last Name'),
  Signer_Email: Yup.string()
    .email('Invalid Email')
    .required('Please Enter a Valid Email'),
  home_phone: Yup.string()
    .max(255)
    .required('Please Enter a Valid Phone Number'),
  mobile_phone: Yup.string()
    .max(255)
    .required('Please Enter a Valid Phone Number')
});

export { validationSchema };
