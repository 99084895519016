import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      flexGrow: 1
    },
    pRelative: {
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      top: '-2px',
      right: '-2px',
      cursor: 'pointer',
      background: theme.palette.background.default
    },
    splitCheckbox: {
      marginTop: 10,
      marginBottom: 10
    },
    mt10: {
      marginTop: 10
    },
    mb20: {
      marginBottom: 20
    },
    addressItem: {
      color: '#000',
      padding: '7px 8px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7'
      }
    },
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    },
    textArea: {
      fontSize: '1rem',
      width: '100%',
      padding: '8px 10px'
    }
  })
);

export { useStyles };
