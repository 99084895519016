export const Staging = {
  REGION: 'us-central1',
  PROJECT_ID: 'pacdocv2-api',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBUc7jJ_PvS8fORd-6-Lju_YWR-CgX6cis'
}

export const Production = {
  REGION: 'us-central1',
  PROJECT_ID: 'pacdocv2-api-prod',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBUc7jJ_PvS8fORd-6-Lju_YWR-CgX6cis'
}

// DOCUSIGN_KEY: '9f6a3653-d8ee-4a21-aaeb-e422d8c5849d',
// DOCUSIGN_SECRETKEY: '0b735cbb-e94b-4886-8858-a160817d4f01'
