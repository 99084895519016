import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from 'src/ThemeComponents/theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    conatiner: {
        fontFamily: "Times New Roman, Times, serif",
        margin: "0px 50px",
    },
    paragraph: {
        textIndent: "40px"
    },
    listTitle: {
        textDecoration: "underline",
        listStyleType: "none"
    },
    Table: {
        borderSpacing: "40px",
    },
    Input: {
        textAlign: "center",
        alignSelf: "center"
    },
    mb2: {
        position: "relative",
        top: "-30px"
    },
    signerSignature: {
        fontSize: '16px',
        fontWeight: 400,
        fontStyle: 'italic'
    },
    brbtm: {
        borderBottom: '1px solid 2B2B2B'
    }
}));  