import React from 'react';
import { Typography, Divider, SvgIcon } from '@material-ui/core';
import Box from '@mui/system/Box';

import { Phone, Clock } from 'react-feather';

import { Page } from '../Components/Page';

import { useStyles } from './Styles/Page';
import { useHOStyles } from './Styles/HoursOfOperation';

const HoursOfOperations = () => {
  const classes = useStyles();
  const hoClasses = useHOStyles();

  return (
    <Page className={classes.root} title="Hours Of Operation">
      <Typography variant="h3">HOURS OF OPERATION</Typography>
      <Box my={2}>
        <Divider variant="fullWidth" />
      </Box>
      <Typography variant="h4">OFFICE HOURS</Typography>
      <Box my={2}>
        <Typography variant="h5">Monday - Friday</Typography>
        <Typography className={hoClasses.textWithIcon} variant="body2">
          <SvgIcon className={hoClasses.icon} fontSize="small" color="primary">
            <Clock />
          </SvgIcon>
          07:00AM - 07:30PM PST
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="h5">Saturday</Typography>
        <Typography className={hoClasses.textWithIcon} variant="body2">
          <SvgIcon className={hoClasses.icon} fontSize="small" color="primary">
            <Clock />
          </SvgIcon>
          08:00AM - 04:00PM PST
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="h5">After Hours Tel</Typography>
        <Typography className={hoClasses.textWithIcon} variant="body2">
          <SvgIcon className={hoClasses.icon} fontSize="small" color="primary">
            <Phone />
          </SvgIcon>
          (310)-597-5511
        </Typography>
      </Box>
    </Page>
  );
};

export { HoursOfOperations };
