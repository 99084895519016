import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 10
  },
  paper: {
    padding: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  removeSelectMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8
  },
  mainContainer: {
    textAlign: 'right'
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  labelTitle: {
    marginRight: 10,
    fontWeight: 'bold',
    color: 'rgb(119, 119, 119)'
  },
  pageTitle: {
    marginTop: 30,
    marginBottom: 40,
    marginLeft: 10
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  gridRoot: {
      flexGrow: 1
    },
  pRelative: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: '-2px',
    right: '-2px',
    cursor: 'pointer',
    background: theme.palette.background.default
  },
  splitCheckbox: {
    marginTop: 10,
    marginBottom: 10
  },
  mt10: {
    marginTop: 10
  },
  mb20: {
    marginBottom: 20
  },
  addressItem: {
    color: '#000',
    padding: '7px 8px',
    cursor: 'pointer',
    '&:hover': {
      background: '#e7e7e7'
    }
  },
  textArea: {
    fontSize: '1rem',
    width: '100%',
    padding: '8px 10px'
  },
  pointer: {
    cursor:'pointer'
  }
}));
