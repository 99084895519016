import React, { useState } from 'react';
import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';

const SignerAddress = ({ location, values, errors, touched, handleChange, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();
  const [addressSearch, handleAddressSearch] = useState('');

  return (
    <>
      <Grid item xs={12} sm={4} md={2}>
        <Typography variant='body2'>Signer Location Name</Typography>
      </Grid>
      <Grid className={classes.pRelative} item xs={12} sm={8} md={10}>
        <TextField
          label='Location Name'
          name={`${location}.w_Signing_Location_Name`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.w_Signing_Location_Name}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item sm={12} md={2}>
        <Typography variant='body2'>Signing Location</Typography>
      </Grid>
      <Grid item sm={12} md={10}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReactGoogleMapLoader
              params={{
                key: 'AIzaSyDy51NKS3CJUDKdqCJ897ZInNDfC203BOM',
                libraries: 'places,geocode',
              }}
              render={googleMaps =>
                googleMaps && (
                  <ReactGooglePlacesSuggest
                    googleMaps={googleMaps}
                    autocompletionRequest={{ input: addressSearch }}
                    onSelectSuggest={geocodedPrediction => {
                      let address = '';
                      handleAddressSearch('');
                      setFieldValue(`${location}.latitude`, geocodedPrediction.geometry.location.lat());
                      setFieldValue(`${location}.longitude`, geocodedPrediction.geometry.location.lng());
                      geocodedPrediction.address_components.forEach(item => {
                        if (item.types[0] === 'locality') {
                          setFieldValue(`${location}.w_Signing_City`, item.short_name);
                        }
                        if (item.types[0] === 'administrative_area_level_1') {
                          setFieldValue(`${location}.w_Signing_State`, item.short_name);
                        }
                        if (item.types[0] === 'postal_code') {
                          setFieldValue(`${location}.w_Signing_Zip`, item.short_name);
                        }
                        if (item.types[0] === 'street_number' || item.types[0] === 'premise') {
                          address = item.long_name;
                        }
                        if (item.types[0] === 'route' || item.types[0] === 'political') {
                          address = `${address} ${item.long_name}`;
                        }
                        setFieldValue(`${location}.w_Signing_Street_Address`, address);
                      })
                    }}
                    textNoResults='Address Not Found'
                    customRender={prediction => (
                      <Typography variant='body2' className={classes.addressItem}>
                        { prediction ?
                          prediction.description :
                          'Address Not Found'
                        }
                      </Typography>
                    )}
                  >
                    <TextField
                      label='Address'
                      name={`${location}.w_Signing_Street_Address`}
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={values.w_Signing_Street_Address}
                      onChange={(e) => {
                        handleChange(e);
                        handleAddressSearch(e.target.value);
                      }}
                      onBlur={handleBlur}
                      helperText={(touched[location] && errors[location]) && errors[location].w_Signing_Street_Address}
                      error={
                        Boolean((touched[location] && errors[location]) && errors[location].w_Signing_Street_Address)
                      }
                      inputProps={{
                        autoComplete: 'none'
                      }}
                      disabled={disabled}
                    />
                  </ReactGooglePlacesSuggest>
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              label='City'
              name={`${location}.w_Signing_City`}
              variant='outlined'
              size='small'
              fullWidth
              value={values.w_Signing_City}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={(touched[location] && errors[location]) && errors[location].w_Signing_City}
              error={Boolean((touched[location] && errors[location]) && errors[location].w_Signing_City)}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.removeSelectMargin} fullWidth margin='dense' variant='outlined'>
              <InputLabel>State</InputLabel>
              <Select
                name={`${location}.w_Signing_State`}
                fullWidth
                value={values.w_Signing_State}
                onChange={handleChange}
                label='State'
                disabled={disabled}
              >
                {states.map((state) =>
                  <MenuItem key={`borrower-${state.code}`} value={state.code}>
                    {state.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label='Zip Code'
              name={`${location}.w_Signing_Zip`}
              variant='outlined'
              size='small'
              fullWidth
              value={values.w_Signing_Zip}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={(touched[location] && errors[location]) && errors[location].w_Signing_Zip}
              error={Boolean((touched[location] && errors[location]) && errors[location].w_Signing_Zip)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { SignerAddress };
