import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from 'src/ThemeComponents/theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  fileDropzoneWrapper: {
    width: '100%',
    minHeight: 100,
    border: '2px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: 10,
    outline: 'none',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileDropInput: {
    width: '100%',
    padding: 10,
  },
  selectedFilesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  fileCard: {
    width: 100,
    height: 100,
    margin: 10,
  },
  cardContent: {
    height: '100%'
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  classesFileTitle: {
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fileListWrapper: {
    padding:'10px 0px'
  },
  fileContainer: {
    border: '1px solid #e7e7e7',
    borderRadius: 4,
    boxShadow: theme.shadows[1]
  },
  fileLabel: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  fullHeight: {
    height: '100%'
  }
}));
