import * as Yup from 'yup';

import { EmailRegex } from '../Regex/Email';
import { PasswordRegex } from '../Regex/Passwrod';
import { checkEmailAvailablility } from '../../Utils/CheckEmail';

let email = '';
let validated = true;

export const initialValues = {
  Signer_First: '',
  Signer_Last: '',
  Signer_Email: '',
  home_phone: '',
  mobile_phone: '',
  auth_type: 'password',
  pass: '',
  confirmPassword: '',
  company_name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  payment_address: '',
  payment_city: '',
  payment_state: '',
  payment_zip: '',
  phone_type: '',
  certified_signing_agent: '',
  send_receive_sms: ''
};

export const validationSchema = Yup.object().shape({
  Signer_First: Yup.string().required('First Name is required'),
  Signer_Last: Yup.string().required('Last Name is required'),
  Signer_Email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .test('checkEmailAvailablility', 'Email not available!', async value => {
      if (
        value &&
        value.length > 9 &&
        email !== value &&
        EmailRegex.test(value)
      ) {
        const res: any = await checkEmailAvailablility(value);
        email = value;
        if (!res || res.success) {
          validated = true;
          return true;
        }
        validated = false;
        return false;
      }

      return validated;
    }),
  home_phone: Yup.string().notRequired(),
  mobile_phone: Yup.string().required('Phone Number is required'),
  pass: Yup.string()
    .required('Password is required')
    .matches(
      PasswordRegex,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  confirmPassword: Yup.string()
    .required('Enter Password Again')
    .oneOf([Yup.ref('pass'), null], 'Passwords must match')
    .matches(
      PasswordRegex,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  address: Yup.string().required('Signer Address is Required.'),
  city: Yup.string().required('Signer City is Required.'),
  state: Yup.string().required('Signer State is Required.'),
  zip: Yup.string().required('Signer Zip is Required.'),
  payment_address: Yup.string().required('Address is Required.'),
  payment_city: Yup.string().required('City is Required.'),
  payment_state: Yup.string().required('State is Required.'),
  payment_zip: Yup.string().required('Zip is Required.'),
  phone_type: Yup.string().required('Phone type is Required.'),
  certified_signing_agent: Yup.string().required('Field is Required.'),
  send_receive_sms: Yup.string().required('Please Select Whether or Not To Receive Text Message'),
});
