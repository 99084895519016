import React from 'react';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { phoneType } from '../../../Common/Rules/SignerForm';

import { useStyles } from '../Styles/Fields';

const PhoneType = ({ values, errors, handleChange}) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6}>
      <FormControl variant='outlined' size='small' classes={{root:classes.selectWidth}} error={errors.phone_type}>
        <InputLabel>Phone Type</InputLabel>
        <Select
          label='Phone Type'
          fullWidth
          name='phone_type'
          value={values.phone_type}
          onChange={handleChange}
        >
          {phoneType.map(phone => {
            return(
              <MenuItem value={phone.value}>{phone.name}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}

export { PhoneType };
