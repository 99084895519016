import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil'
import {
  Typography,
  Grid,
  Avatar
} from '@material-ui/core';
import { Toast } from '../../Toasts';

import { useStyles } from '../Styles/Style'

import { uploadProfilePicture } from './../../../Recoil/Selectors/Auth'

const ProfilePicture = () => {

  const classes = useStyles()

  const [toast, setToast] = useState({
  message: '',
  type: 'success',
  open: false
  });

  const uploadImage = useRecoilCallback(({ snapshot }) => async (file: any) => {
    const res = await snapshot.getPromise(uploadProfilePicture(file));
    if (res.success) {
      setToast({
        message: 'Profile Picture Updated',
        type: 'success',
        open: true
      });
    } else {
      setToast({
        message: 'Failed to Update Profile Picture',
        type: 'error',
        open: true
      });
    }
  })
  
  const handleFile = (e) => {
    const file = e.target.files[0]
    if (file) {
      uploadImage(file)
    }
  }

  return (
  <Grid item xs={12}>
    <Grid container className={classes.formGroup}>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant='body2' className={classes.labelTitle}>
          Profile Picture
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Avatar onClick={()=>document.getElementById('profile-picture').click()} className={`${classes.large} ${classes.pointer}`}>H</Avatar>
        <input accept=".png,.jpg,.jpeg" onChange={handleFile} type='file' hidden name='profile-picture' id='profile-picture'/>
        <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      </Grid>
    </Grid>
  </Grid>
  )
}

export { ProfilePicture }