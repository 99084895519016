import React, { useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilCallback
} from 'recoil';

import {
  missingDocsModal,
} from './../Recoil/Atoms/Login'

import { Page } from '../Components/Page';
import { GeneralModal } from '../Components/Modal';
import { OrdersList } from '../Components/Orders/OrdersList';

import { EditOrder } from '../Components/Orders/EditOrder';
import { ViewOrder } from '../Components/Orders/ViewOrder';
import { Toast } from '../Components/Toasts';

import { currentOrder, refetchOrders } from '../Recoil/Atoms/Orders';
import { editOrder, getSignerOrders } from '../Recoil/Selectors/Orders';
import { SearchOrders } from 'src/Components/Orders/SearchOrders';

import GeneralStepper from 'src/Components/Stepper';

import { useStyles } from './Styles/Page';

const Orders = () => {
  let Form = null;
  const classes = useStyles();

  const [title, handleTitle] = useState('');
  const [modal, handleModal] = useState(false);

  const [missingDocModal, handleMissingDocModal] = useRecoilState(missingDocsModal);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const callRefetch = useSetRecoilState(refetchOrders);

  const order: any = useRecoilValue(currentOrder);

  const signersOrders: any = useRecoilValueLoadable(getSignerOrders);
  const orders = signersOrders?.contents?.result?.rows || [];
  const total = signersOrders?.contents?.result?.count || 0;

  const refetch = () => callRefetch(n => n + 1);

  const closeDocModal = () => {
    handleMissingDocModal(false)
  }

  const openModal = (modalTitle, open) => {
    handleTitle(modalTitle);
    handleModal(open);
  };

  const handleSubmit = e => {
    if (title === 'Cancel Order') {
      handleCancel();
    }
    if (Form) {
      Form(e);
    }
  };

  const bindSubmitForm = submitForm => {
    Form = submitForm;
  };

  const handleLoading = (value, message, type) => {
    setLoading(value);
    if (!value && message) {
      openModal('', false);
      setToast({
        message,
        type,
        open: true
      });
      refetch();
    }
  };

  const handleCancel = useRecoilCallback(({ snapshot }) => async () => {
    const params: any = Object.assign(
      {},
      {
        location_one: { ...order }
      }
    );
    params.location_one.f_status_web = 'Cancelled';

    const res = await snapshot.getPromise(editOrder(params));
    if (res.success) {
      handleLoading(false, 'Order Cancelled Successfully!', 'success');
    } else {
      handleLoading(false, 'Unable to Cancel the Order!', 'error');
    }
  });

  return (
    <Page className={classes.root} title='Orders'>

      <Toast
        message={toast.message}
        type={toast.type}
        open={toast.open}
        setToast={setToast}
      />
      <SearchOrders></SearchOrders>
      <OrdersList
        type='myOrders'
        orders={orders}
        loading={signersOrders?.state === 'loading'}
        total={total}
        openModal={openModal}
      />
      {modal && (
        <GeneralModal
          open={modal}
          title={title}
          loading={loading}
          openModal={openModal}
          handleSubmit={handleSubmit}
          noSubmit={title === 'View Order'}
        >
          {title === 'View Order' && <ViewOrder />}
          {title === 'Edit Order' && (
            <EditOrder
              bindSubmitForm={bindSubmitForm}
              handleLoading={handleLoading}
            />
          )}
        </GeneralModal>
      )}
      {missingDocModal && (
        <GeneralModal
          open={missingDocModal}
          title='Missing Documents'
          loading={loading}
          maxWidth='lg'
          openModal={closeDocModal}
          noSubmit={true}
        >
          <GeneralStepper closeModal={closeDocModal} />
        </GeneralModal>
      )}
    </Page>
  );
};

export { Orders };
