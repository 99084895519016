import React from 'react';
import { Grid, Typography, TextareaAutosize } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const SpecialInstruction = ({ location, values, handleChange, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant='body2'>Special Instructions</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <TextareaAutosize
          className={classes.textArea}
          name={`${location}.w_Additional_Information`}
          rowsMin={3}
          value={values.w_Additional_Information}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { SpecialInstruction };
