import React from 'react';
import { Grid, Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const ReceiveTextMessage = ({ values, handleChange }) => {
  return (
    <>
      <Grid item sm={12} xs={12} md={12}  lg={12}>
        <Typography variant='body2'>Can You Receive or Send Text Messages?</Typography>
        <RadioGroup row aria-label="send_receive_sms" name="send_receive_sms" value={values.send_receive_sms} onChange={handleChange}>
          <FormControlLabel value="Y" control={<Radio />} label="Yes" />
          <FormControlLabel value="N" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>
    </>
  );
}

export { ReceiveTextMessage };
