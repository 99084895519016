import React, { useState, useRef } from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import Box from '@mui/system/Box';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';

import { OrdersForm } from './OrderForm';
import { SingleLoanNumber } from './Fields/SingleLoanNumber';

import { currentOrder } from '../../Recoil/Atoms/Orders';
import { editOrder } from '../../Recoil/Selectors/Orders';

import { validationSchemaForExisting } from '../../Common/Rules/OrdersForm';
import { createPayload } from '../../Common/CreatePayload';
import { updateAdditionalBorrowers } from '../../Common/UpdateAdditionalBorrowers';

const EditOrder = ({ bindSubmitForm, handleLoading }) => {
  const alertRef = useRef(null);
  const [alert, setAlert] = useState(null);

  const order: any = useRecoilValue(currentOrder);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { resetForm }) => {
    const params = createPayload(values);
    const updatedAdditionalBorrowers = updateAdditionalBorrowers(values, order);
    params.location_one.additionalBorrowersModel = [...updatedAdditionalBorrowers];

    handleLoading(true);

    const res = await snapshot.getPromise(editOrder(params));

    if (res.success) {
      resetForm();
      handleLoading(false, 'Order Updated Successfully!', 'success');
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
      alertRef.current.scrollIntoView();
      handleLoading(false);
    }
  });

  return (
    <Formik
      initialValues={{ location_one: order }}
      validationSchema={validationSchemaForExisting}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        submitForm
      }: any) => {
        bindSubmitForm(submitForm);
        return (
          <Form onSubmit={handleSubmit} placeholder={''}>
            <SingleLoanNumber
              value={values.location_one.w_Loan_Number}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={false}
            />
            <OrdersForm
              location='location_one'
              values={{ ...values.location_one }}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              disabled={false}
            />
            { alert &&
              <Box mt={3}>
                <Alert ref={alertRef} severity={alert.status}>{alert.message}</Alert>
              </Box>
            }
          </Form>
        )
      }}
    </Formik>
  );
};

export { EditOrder };
