import React, { useState } from 'react';
import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';

const SignerAddress = ({ values, errors, touched, handleChange, handleBlur, setFieldValue, disabled, hideAddress }) => {
  const classes = useStyles();
  const [addressSearch, handleAddressSearch] = useState('');
  return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ReactGoogleMapLoader
            params={{
              key: 'AIzaSyDy51NKS3CJUDKdqCJ897ZInNDfC203BOM',
              libraries: 'places,geocode',
            }}
            render={googleMaps =>
              googleMaps && (
                <ReactGooglePlacesSuggest
                  googleMaps={googleMaps}
                  autocompletionRequest={{ input: addressSearch }}
                  onSelectSuggest={geocodedPrediction => {
                    let address = '';
                    handleAddressSearch('');
                    geocodedPrediction.address_components.forEach(item => {
                      if (item.types[0] === 'locality') {
                        setFieldValue('city', item.short_name);
                        hideAddress && setFieldValue('city', item.short_name);
                      }
                      if (item.types[0] === 'administrative_area_level_1') {
                        setFieldValue('state', item.short_name);
                        hideAddress && setFieldValue('PaymentState', item.short_name);
                      }
                      if (item.types[0] === 'postal_code') {
                        setFieldValue('zip', item.short_name);
                        hideAddress && setFieldValue('PaymentZip', item.short_name);
                      }
                      if (item.types[0] === 'street_number' || item.types[0] === 'premise') {
                        address = item.long_name;
                      }
                      if (item.types[0] === 'route' || item.types[0] === 'political') {
                        address = `${address} ${item.long_name}`;
                      }
                      setFieldValue('address', address);
                      hideAddress && setFieldValue('address', address);
                    })
                  }}
                  textNoResults='Address Not Found'
                  customRender={prediction => (
                    <Typography variant='body2' className={classes.addressItem}>
                      { prediction ?
                        prediction.description :
                        'Address Not Found'
                      }
                    </Typography>
                  )}
                >
                  <TextField
                    placeholder='Address'
                    name='address'
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={values.address}
                    onChange={(e) => {
                      handleChange(e);
                      handleAddressSearch(e.target.value);
                    }}
                    onBlur={handleBlur}
                    helperText={touched.address && errors.address}
                    error={Boolean(touched.address && errors.address)}
                    inputProps={{
                      autoComplete: 'none'
                    }}
                    disabled={disabled}
                  />
                </ReactGooglePlacesSuggest>
              )
            }
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextField
            placeholder='City'
            name='city'
            variant='outlined'
            size='small'
            fullWidth
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.city && errors.city}
            error={Boolean(touched.city && errors.city)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <FormControl variant='outlined' size='small' classes={{root:classes.selectWidth}}>
            <InputLabel>State</InputLabel>
            <Select
              label='State'
              name='state'
              fullWidth
              value={values.state}
              onChange={handleChange}
              placeholder='State'
              disabled={disabled}
            >
              {states.map((state) =>
                <MenuItem key={`borrower-${state.code}`} value={state.code}>
                  {state.name}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={4}>
          <TextField
            placeholder='Zip Code'
            name='zip'
            variant='outlined'
            size='small'
            fullWidth
            value={values.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.zip && errors.zip}
            error={Boolean(touched.zip && errors.zip)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
  );
}

export { SignerAddress };
