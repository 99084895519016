import * as React from 'react';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil'
import { styled, Box } from '@mui/system';
import Button from '@mui/material/Button';
// import ModalUnstyled from '@mui/core/ModalUnstyled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getSignerNotifications, updateSignerNotification } from './../../Recoil/Selectors/Announcements';
import moment from 'moment';

const StyledModal = styled('div')<{open?: boolean, onClose?: any, BackdropComponent?: any}>`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 800
};

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

export default function ModalUnstyledDemo() {
  const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const fetchedAnnouncements: any = useRecoilValueLoadable(getSignerNotifications);
  // const announcements = fetchedAnnouncements?.contents?.result?.data || [];
  // React.useEffect(() => {
  //   if (announcements?.length === 0) {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // }, [announcements])

  // const onSubmit = useRecoilCallback(({ snapshot }) => async () => {
  //   await snapshot.getPromise(updateSignerNotification);
  //   setOpen(false);
  // });

  return (

    <div>
      {/* <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
              <div style={{overflow:'scroll', height:'500px'}}>
                {announcements?.map((row) => (
                  
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.announcement}
                    </TableCell>
                    <TableCell align="right">{`${moment(row.created_on).toString()}`}</TableCell>
                  </TableRow>
                  
                ))}
                </div>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button variant="contained" style={{float: 'right'}} onClick={onSubmit}>Acknowledge</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </div>
          </TableContainer>
        </Box>
      </StyledModal> */}
    </div>
  );
}