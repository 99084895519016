import React, { useState } from 'react';
import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';

const PaymentAddress = ({ values, errors, touched, handleChange, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();
  const [addressSearch, handleAddressSearch] = useState('');

  return (
    <Grid className={classes.gridRoot} container spacing={3}>
      <Grid item xs={12} md={12}>
        <ReactGoogleMapLoader
          params={{
            key: 'AIzaSyDy51NKS3CJUDKdqCJ897ZInNDfC203BOM',
            libraries: 'places,geocode',
          }}
          render={googleMaps =>
            googleMaps && (
              <ReactGooglePlacesSuggest
                googleMaps={googleMaps}
                autocompletionRequest={{ input: addressSearch }}
                onSelectSuggest={geocodedPrediction => {
                  let address = '';
                  handleAddressSearch('');
                  geocodedPrediction.address_components.forEach(item => {
                    if (item.types[0] === 'locality') {
                      setFieldValue('payment_city', item.short_name);
                    }
                    if (item.types[0] === 'administrative_area_level_1') {
                      setFieldValue('payment_state', item.short_name);
                    }
                    if (item.types[0] === 'postal_code') {
                      setFieldValue('payment_zip', item.short_name);
                    }
                    if (item.types[0] === 'street_number' || item.types[0] === 'premise') {
                      address = item.long_name;
                    }
                    if (item.types[0] === 'route' || item.types[0] === 'political') {
                      address = `${address} ${item.long_name}`;
                    }
                    setFieldValue('payment_address', address);
                  })
                }}
                textNoResults='Address Not Found'
                customRender={prediction => (
                  <Typography variant='body2' className={classes.addressItem}>
                    { prediction ?
                      prediction.description :
                      'Address Not Found'
                    }
                  </Typography>
                )}
              >
                <TextField
                  label='Payment Address'
                  name='payment_address'
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={values.payment_address}
                  onChange={(e) => {
                    handleChange(e);
                    handleAddressSearch(e.target.value);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.payment_address && errors.payment_address}
                  error={Boolean(touched.payment_address && errors.payment_address)}
                  inputProps={{
                    autoComplete: 'none'
                  }}
                  disabled={disabled}
                />
              </ReactGooglePlacesSuggest>
            )
          }
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField
          label='City'
          name='payment_city'
          variant='outlined'
          size='small'
          fullWidth
          value={values.payment_city}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.payment_city && errors.payment_city}
          error={Boolean(touched.payment_city && errors.payment_city)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <FormControl variant='outlined' size='small' classes={{ root: classes.selectWidth }}>
          <InputLabel>State</InputLabel>
          <Select
            name='payment_state'
            fullWidth
            value={values.payment_state}
            onChange={handleChange}
            label='State'
            disabled={disabled}
          >
            {states.map((state) =>
              <MenuItem key={`borrower-${state.code}`} value={state.code}>
                {state.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField
          label='Zip Code'
          name='payment_zip'
          variant='outlined'
          size='small'
          fullWidth
          value={values.payment_zip}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.payment_zip && errors.payment_zip}
          error={Boolean(touched.payment_zip && errors.payment_zip)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export { PaymentAddress };
