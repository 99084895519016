import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const SignerPhone = ({ location, values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={4} md={2}>
        <Typography variant='body2'>Signing Phone</Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        { disabled ?
          <TextField
            label='Signing Phone'
            name={`${location}.w_Signing_Phone_Number`}
            variant='outlined'
            size='small'
            fullWidth
            value={values.w_Signing_Phone_Number}
            disabled={disabled}
          /> :
          <InputMask
            mask='(999)999-9999'
            maskChar=''
            value={values.w_Signing_Phone_Number}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {() =>
              <TextField
                label='Signing Phone'
                name={`${location}.w_Signing_Phone_Number`}
                variant='outlined'
                size='small'
                fullWidth
                helperText={(touched[location] && errors[location]) && errors[location].w_Signing_Phone_Number}
                error={Boolean((touched[location] && errors[location]) && errors[location].w_Signing_Phone_Number)}
              />
            }
          </InputMask>
        }
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Typography variant='body2'>Home Phone</Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        { disabled ?
          <TextField
            label='Signing Phone'
            name={`${location}.w_Borrower_Home_Phone`}
            variant='outlined'
            size='small'
            fullWidth
            value={values.w_Borrower_Home_Phone}
            disabled={disabled}
          /> :
          <InputMask
            mask='(999)999-9999'
            maskChar=''
            value={values.w_Borrower_Home_Phone}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          >
            {() =>
              <TextField
                label='Home Phone'
                name={`${location}.w_Borrower_Home_Phone`}
                variant='outlined'
                size='small'
                fullWidth
              />
            }
          </InputMask>
        }
      </Grid>
    </>
  );
}

export { SignerPhone };
