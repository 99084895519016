import React, { useState } from 'react';
import {
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilCallback
} from 'recoil';

import { Page } from '../Components/Page';
import { Toast } from '../Components/Toasts';

import { getAllSignerUsers, addSignerUser } from './../Recoil/Selectors/Users';
import { refetechAllSignerUsers } from './../Recoil/Atoms/Users';

import { useStyles } from './Styles/Page';
import { UsersList } from '../Components/Users/UsersList';

import {
  // Box,
  Button,
  Card,
  CardContent, CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import Box from '@mui/system/Box';
import { Formik } from 'formik';
import { Schema } from '../Common/Rules/ChangePassword';

const Users = () => {
  const classes = useStyles();
  const [hide, setHide] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const callRefetch = useSetRecoilState(refetechAllSignerUsers);

  const users: any = useRecoilValueLoadable(getAllSignerUsers)

  const usersList = users?.contents?.result;

  const refetch = () => callRefetch(n => n + 1);

  const openModal = (modalTitle, open) => {

  };

  const addUser = useRecoilCallback(({ snapshot }) => async () => {
    if (firstName === '') {
      setToast({
        message: 'First Name is required',
        type: 'error',
        open: true
      });
      return;
    }
    if (lastName === '') {
      setToast({
        message: 'Last Name is required',
        type: 'error',
        open: true
      });
      return;
    }
    if (username === '') {
      setToast({
        message: 'username is required',
        type: 'error',
        open: true
      });
      return;
    }
    if (password === '') {
      setToast({
        message: 'password is required',
        type: 'error',
        open: true
      });
      return;
    }
    try {
      const response = await snapshot.getPromise(addSignerUser({
        username,
        password,
        Signer_First: firstName,
        Signer_Last: lastName
      }));
      if (response.success === false) {
        setToast({
          message: 'User Already Registered',
          type: 'error',
          open: true
        });
      } else {
        refetch();
        setToast({
          message: 'Add Signer User Successfully',
          type: 'success',
          open: true
        });
        setHide(false);
      }
    } catch (e) {
      setToast({
        message: 'Signer User didnt successfully',
        type: 'error',
        open: true
      });
    }
  });

  return (
    <Page className={classes.root} title='Orders'>
      <Toast
        message={toast.message}
        type={toast.type}
        open={toast.open}
        setToast={setToast}
      />
      {
        hide === true ?
          <Page title='Add User'>
            <div className={classes.PageTitleWrapper}>
              <Typography className={classes.pageTitle}>Add User</Typography>
            </div>
            <Card classes={{ root: classes.changePasswordCard }}>
              <CardContent>
                <Formik
                  initialValues={{
                    username: '',
                    newPassword: '',
                    confirmPassword: ''
                  }}
                  validationSchema={Schema}
                  onSubmit={addUser}
                >
                  {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      errors
                    }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        label='First Name'
                        name='username'
                        type='text'
                        variant='outlined'
                        size='small'
                        fullWidth
                        classes={{ root: classes.passwordField }}
                        onChange={(e) => { setFirstName(e.target.value) }}
                        onBlur={handleBlur}
                      />
                      <TextField
                        label='Last Name'
                        name='username'
                        type='text'
                        variant='outlined'
                        size='small'
                        fullWidth
                        classes={{ root: classes.passwordField }}
                        onChange={(e) => { setLastName(e.target.value) }}
                        onBlur={handleBlur}
                      />
                      <TextField
                        label='Username'
                        name='username'
                        type='text'
                        variant='outlined'
                        size='small'
                        fullWidth
                        classes={{ root: classes.passwordField }}
                        onChange={(e) => { setUsername(e.target.value) }}
                        onBlur={handleBlur}
                      />
                      <TextField
                        label='Password'
                        name='Password'
                        type='password'
                        variant='outlined'
                        size='small'
                        fullWidth
                        classes={{ root: classes.passwordField }}
                        onChange={(e) => { setPassword(e.target.value) }}
                        onBlur={handleBlur}
                      />
                      <Box mt={3} sx={{display:"flex",justifyContent:"flex-end"}}>
                 
                        <Button
                        className='me-2'
                          type='button'
                          color='secondary'
                          classes={{ root: classes.buttonField }}
                          variant='contained'
                          fullWidth={false}
                          onClick={() => {
                            setHide(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type='submit'
                          color='primary'
                          variant='contained'
                          className="mo-3"
                          fullWidth={false}
                          disabled={isSubmitting}
                          onClick={() => {
                            addUser();
                          }}
                        >
                          {isSubmitting && (
                            <CircularProgress
                              color='inherit'
                              size={25}
                            />
                          )}
                          Submit
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Page> :
          <>
            <div className='row'>
              <button className={'doka--button-app'} onClick={() => { setHide(true) }} style={{
                backgroundColor: '#3c6ab5',
                color: 'white',
                padding: '0.5%',
                borderRadius: '10%',
                borderColor: 'transparent' }}>Add User</button>
            </div>
            <UsersList
              type='myOrders'
              users={usersList}
              loading={users?.state === 'loading'}
              openModal={openModal}
            />
          </>
      }
    </Page>
  );
};

export { Users };
