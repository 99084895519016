import React from 'react';
import { Grid, Typography, FormControlLabel, FormControl, Checkbox } from '@material-ui/core';

const NotifiedVia = ({ values, setFieldValue }) => {

  const getValue = (value: String) => {
    const communication = [ ...values.prfCom ]
    const optionIndex = values.prfCom.indexOf(value)
    if (optionIndex === -1) {
      communication.push(value)
    } else {
      communication.splice(optionIndex, 1)
    }
    return communication
  }

  return (
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='body2'>I Want To Be Notified About New Order Via</Typography>
        <FormControl component='fieldset'>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  value = 'EmailNewOrder'
                  checked={values.prfCom.includes('EmailNewOrder')}
                  onChange={(e)=> setFieldValue('prfCom', getValue(e.target.value))}
                  name='NotifyVia'
                  color='primary'
                />
              }
              label='Email'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value = 'Text messageNewOrder'
                  checked={values.prfCom.includes('Text messageNewOrder')}
                  onChange={(e) => setFieldValue('prfCom', getValue(e.target.value))}
                  name='NotifyVia'
                  color='primary'
                />
              }
              label='Text Message'
            />
          </Grid>
        </FormControl>
      </Grid>
  );
}

export { NotifiedVia };
