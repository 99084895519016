import React, { useState } from 'react'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import { GeneralModal } from 'src/Components/Modal'
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { submitInvoice, invoiceDispute } from 'src/Recoil/Selectors/Orders';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { refetchOrders } from 'src/Recoil/Atoms/Orders';
import { storage } from '../../Services/storage';


const ViewInvoice = ((props: any) => {
  const { orderDetails, close, open } = props
  const signer = JSON.parse(storage.get('user') || "{}")

  const [invoiceDisputeModal, setDisputeModal] = useState(false)
  const [disputeNote, setDisputeNote] = useState('')

  const [loader, setLoader] = useState('')

  const refetch = () => callRefetch(n => n + 1);
  const callRefetch = useSetRecoilState(refetchOrders);

  const handleSubmit = useRecoilCallback(({ snapshot }) => async () => {
    setLoader('submit')
    const res = await snapshot.getPromise(submitInvoice(orderDetails));
    setLoader('')
    if (res.success) {
      refetch()
      close()
      enqueueSnackbar(
        res?.result?.message || 'Invoice submitted',
        {
          variant: 'success'
        }
      )
    } else {
    enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });

  const submitDispute = useRecoilCallback(({ snapshot }) => async () => {
    setLoader('dispute')

    const res = await snapshot.getPromise(invoiceDispute({
      Id: orderDetails.Id,
      w_Loan_Number: orderDetails.w_Loan_Number,
      message: disputeNote
    }));
    setLoader('')
    if (res.success) {
      setDisputeNote('')
      setDisputeModal(false)
      enqueueSnackbar(
        res?.result?.message || 'Action Completed',
        {
          variant: 'success'
        }
      )
    } else {
      props.enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
  });
  // const applicableFees = signer?.signer_type === 'Notary' ? 'fee_amount' : 'attorney_fee'

  const fees = orderDetails?.fees || []
  // const totalFees = fees.length ?
  //   fees.map(item => item[applicableFees]).reduce((prev, curr) => prev + curr, 0) : 0

  return (
    <GeneralModal
      loading={loader === 'submit'}
      title='View Invoice'
      openModal={close}
      open={open}
      submitLable='Submit'
      handleSubmit={handleSubmit}
    >
      <GeneralModal
        loading={loader === 'dispute'}
        title='Invoice Dispute'
        openModal={setDisputeModal}
        open={invoiceDisputeModal}
        submitLable='Submit'
        handleSubmit={submitDispute}
      >
        Please submit the disputes below and we will review and get back to you.
        Please do not submit the invoice until we contact you
        <br></br>
        <br></br>
        {' '}
        <TextField
          onChange={e => setDisputeNote(e.target.value)}
          value={disputeNote}
          multiline
          rows={5}
          variant='outlined'
          fullWidth
        >
        </TextField>
      </GeneralModal>
      <Grid container spacing={4}>


        <Grid item container xs={5} justify='space-around'>
          <Grid item container justify='flex-end'>
            <Grid item xs={8}>
              <b>From</b>
              <Typography>
                <br></br>
                {signer?.Signer_First} {signer?.Signer_Last}
              </Typography>
              <Typography>
                {signer?.address && signer?.address?.split(',')[0]}
              </Typography>
              <Typography>
                {signer?.address && signer?.address?.split(',').filter((a, index) => index).toString()}

                <br></br>
                <br></br>
                <br></br>

              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Appointment Date
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_appointment_date}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Appointment Time
                </b>
              </Typography>
              <Typography>
                {orderDetails?.f_appointment_time}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Client Name
                </b>
              </Typography>

              <Typography>
                {orderDetails?.client?.clientName}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Borrower Last Name
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_borrower_last_name}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Borrower City
                </b>
              </Typography>

              <Typography>
                {orderDetails?.w_Signing_City}  {/* {orderDetails?.f_borrower_city} */}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Status
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_status_web}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6} justify='space-around'>
          <Grid item container justify='space-around'>
            <Grid item xs={8}>
              <b>To</b>
              <Typography>
                <br></br>
            Pacific Document Signing Inc
          </Typography>

              <Typography>
                10801 National Blvd. Suite #510
          </Typography>
              <Typography>
                Los Angeles, CA   90064
                <br></br>
                <br></br>
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Invoice #
                </b>
              </Typography>
              <Typography>
                INV{orderDetails?.Id}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Invoice Date
                </b>
              </Typography>
              <Typography>
                {moment(orderDetails?.invoice_submitted_datetime).format('MM/DD/YYYY') !== 'Invalid date' ?
                  moment(orderDetails?.invoice_submitted_datetime).format('MM/DD/YYYY') :
                  moment().format('MM/DD/YYYY')}
              </Typography>
            </Grid>
            <Grid container item xs={8} justify='space-between'>
              <Typography>
                <b>
                  Amount Due
                </b>
              </Typography>
              <Typography>
                ${orderDetails?.f_signer_fees} USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justify='space-around' >
          <Grid item xs={9}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fee</TableCell>
                  <TableCell align='right'>Fee Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!fees?.length && (
                  <TableRow>
                    <TableCell colSpan={2}>No data available</TableCell>
                  </TableRow>
                )}
                {fees.map(fee => (
                  <TableRow>
                    <TableCell>{fee.fee_name}</TableCell>
                    <TableCell align='right'>{orderDetails?.Signer_Type === 'Notary' ? fee.fee_amount : fee.attorney_fee}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container item justify='flex-end'>
              <Grid item xs={12} container justify='flex-end'>
                <Typography><b>Total	${orderDetails?.f_signer_fees} USD</b></Typography>
              </Grid>
              <Grid item xs={12} container justify='flex-end'>
                <Typography><b> Amount Due	${orderDetails?.f_signer_fees} USD</b></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Alert icon={false} severity='success'>
            <b> Disclaimer : </b>
             This Invoice is subject to approval by the operation
            and accounting departments of PacDocSign for accuracy.
           </Alert>

        </Grid>
        <Grid xs={12} item>
          <Button color='primary' onClick={e => setDisputeModal(true)}>
            Invoice Disputes
         </Button>
        </Grid>
      </Grid>
    </GeneralModal>
  )
})
export { ViewInvoice }
