import React  from 'react';
import {
  useRecoilCallback,
  useSetRecoilState
} from 'recoil';
import { FormControl, InputLabel } from '@material-ui/core';

import { GeneralModal } from '../Modal';

import { deactivateUser } from '../../Recoil/Selectors/Users';
import { refetechAllSignerUsers } from '../../Recoil/Atoms/Users';

export const DeactivateConfirmation = ((props: any) => {
  const { handleConfirmationModal, open, user } = props
  const callRefetch = useSetRecoilState(refetechAllSignerUsers);

  const refetch = () => callRefetch(n => n + 1);

  const updateStatus = useRecoilCallback(({ snapshot }) => async () => {
    const res = await snapshot.getPromise(deactivateUser({
      signer_id: user.signer_id
    }));
    if (res.success) {
      refetch()
      handleConfirmationModal(false)
      props.enqueueSnackbar(
        'User Deactivated Successfully',
        {
          variant: 'success'
        }
      )
      window.location.reload();
    } else {
      props.enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
      window.location.reload();
    }
  });
  return (
    <>
      {
        user?.Signer_Status === 'active' ?
          <GeneralModal
            title='Deactivate User'
            submitLabel='Submit'
            handleSubmit={updateStatus}
            openModal={e => handleConfirmationModal(false)}
            open={open}
          >
            <FormControl
              fullWidth
              variant='outlined'
              size='small'
            >
              <InputLabel>Deactivate User</InputLabel>
            </FormControl>
          </GeneralModal>
          :
          <GeneralModal
            title='Activate User'
            submitLabel='Submit'
            handleSubmit={updateStatus}
            openModal={e => handleConfirmationModal(false)}
            open={open}
          >
            <FormControl
              fullWidth
              variant='outlined'
              size='small'
            >
              <InputLabel>Activate User</InputLabel>
            </FormControl>
          </GeneralModal>
      }
    </>
  );
});
