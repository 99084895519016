import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const CompanyName = ({ values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        label='Company Name'
        name='company_name'
        variant='outlined'
        size='small'
        fullWidth
        value={values.company_name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.company_name && errors.company_name}
        error={Boolean(touched.company_name && errors.company_name)}
        disabled={disabled}
      />
    </Grid>
  );
}

export { CompanyName };
